import dayjs, { Dayjs } from "dayjs";
import {
  CheckBoxInput,
  DateTimeInput,
  DropDownLookUpInput,
  FileInput,
  FormFields,
  MultilineInput,
  MultipleOptionSetInput,
  NumberInput,
  OptionSetInput,
  TextInput,
} from "../../../types";
import activityDisplayNames from "../displayNames";
import { activityOptionSetChoices } from "../optionSetChoices";

export type EditActivityFields = {
  vdl_debut: DateTimeInput;
  vdl_fin: DateTimeInput;
  vdl_details: MultilineInput;

  vdl_adressetoilettes: TextInput;

  vdl_attestationassurancerespo: CheckBoxInput;
  vdl_attestationfournisseurequipement: CheckBoxInput;
  vdl_attestationoperateur: CheckBoxInput;

  vdl_bacroulantbrun240l: NumberInput;

  vdl_datesethoraire: MultilineInput;

  vdl_document1: FileInput;
  vdl_document2: FileInput;
  vdl_document3: FileInput;

  vdl_duobacsroulants240l: NumberInput;
  vdl_duobarils205l: NumberInput;

  vdl_detailsaffichages: MultilineInput;
  vdl_detailsspecifiques: MultilineInput;
  vdl_detailsstructures: MultilineInput;

  vdl_adressesite: TextInput;

  vdl_nombreparticipants: NumberInput;
  vdl_nopointsdevente: NumberInput;
  vdl_nbtoilette_mobilitereduite: NumberInput;
  vdl_nbtoilette: NumberInput;

  vdl_profitsactivite: TextInput;
  vdl_precisionlieusoutieneco: TextInput;
  vdl_detailssautresaffichages: MultilineInput;
  vdl_raison: TextInput;
  vdl_ruedirection: TextInput;

  vdl_slimjimcompost: NumberInput;
  vdl_slimjimnoir90l: NumberInput;
  vdl_slimjimrecyclage: NumberInput;

  vdl_detailssautresstructures: MultilineInput;
  vdl_trajet: MultilineInput;

  vdl_typeaccessoirefeu: MultipleOptionSetInput;
  vdl_typeappareilcombustion: MultipleOptionSetInput;
  vdl_typeartistedefeu: MultipleOptionSetInput;
  vdl_typeactions: MultipleOptionSetInput;
  vdl_typecombustible: MultipleOptionSetInput;

  vdl_typedefeu: MultipleOptionSetInput;
  vdl_typedeplacement: OptionSetInput;
  vdl_typefermeturederue: OptionSetInput;
  vdl_typevoiepublique: MultipleOptionSetInput;
  vdl_typepyro: MultipleOptionSetInput;
  vdl_typeserviceeco: MultipleOptionSetInput;
  vdl_typesaffichages: MultipleOptionSetInput;
  vdl_typestructure: MultipleOptionSetInput;

  vdl_ventedemandeur: CheckBoxInput;
  vdl_ventetiers: CheckBoxInput;
};

const editActivityFields: EditActivityFields = {
  vdl_debut: {
    type: "datetime",
    error: "",
    value: null,
    required: true,
    fillLine: false,
    label: activityDisplayNames.vdl_debut,
    disablePast: true
  },
  vdl_fin: {
    type: "datetime",
    error: "",
    value: null,
    required: true,
    fillLine: false,
    label: activityDisplayNames.vdl_fin,
    disablePast: true,
    minDate: (fields: FormFields) =>
      (fields.vdl_debut.value as Dayjs)
        ? (fields.vdl_debut.value as Dayjs)
        : undefined
  },
  vdl_details: {
    type: "multiline",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    rows: {
      min: 4,
      max: 4,
    },
    label: activityDisplayNames.vdl_details,
  },
  vdl_adressetoilettes: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_adressetoilettes,
  },
  vdl_attestationassurancerespo: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_attestationassurancerespo,
  },
  vdl_attestationfournisseurequipement: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_attestationfournisseurequipement,
  },
  vdl_attestationoperateur: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_attestationoperateur,
  },

  vdl_bacroulantbrun240l: {
    type: "number",
    error: "",
    value: null,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_bacroulantbrun240l,
  },
  vdl_datesethoraire: {
    type: "multiline",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_datesethoraire,
  },

  vdl_document1: {
    type: "file",
    error: "",
    value: null,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_bacroulantbrun240l,
  },
  vdl_document2: {
    type: "file",
    error: "",
    value: null,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_bacroulantbrun240l,
  },
  vdl_document3: {
    type: "file",
    error: "",
    value: null,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_bacroulantbrun240l,
  },

  vdl_duobacsroulants240l: {
    type: "number",
    error: "",
    value: null,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_duobacsroulants240l,
  },
  vdl_duobarils205l: {
    type: "number",
    error: "",
    value: null,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_duobarils205l,
  },

  vdl_detailsaffichages: {
    type: "multiline",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_detailsaffichages,
  },
  vdl_detailsspecifiques: {
    type: "multiline",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_detailsspecifiques,
  },
  vdl_detailsstructures: {
    type: "multiline",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_detailsstructures,
  },

  vdl_adressesite: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_adressesite,
  },

  vdl_nombreparticipants: {
    type: "number",
    error: "",
    value: null,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_nombreparticipants,
  },
  vdl_nopointsdevente: {
    type: "number",
    error: "",
    value: null,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_nopointsdevente,
  },
  vdl_nbtoilette_mobilitereduite: {
    type: "number",
    error: "",
    value: null,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_nbtoilette_mobilitereduite,
  },
  vdl_nbtoilette: {
    type: "number",
    error: "",
    value: null,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_nbtoilette,
  },

  vdl_profitsactivite: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_profitsactivite,
  },
  vdl_precisionlieusoutieneco: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_precisionlieusoutieneco,
  },
  vdl_detailssautresaffichages: {
    type: "multiline",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_detailssautresaffichages,
  },
  vdl_raison: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_raison,
  },
  vdl_ruedirection: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_ruedirection,
  },

  vdl_slimjimcompost: {
    type: "number",
    error: "",
    value: null,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_slimjimcompost,
  },
  vdl_slimjimnoir90l: {
    type: "number",
    error: "",
    value: null,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_slimjimnoir90l,
  },
  vdl_slimjimrecyclage: {
    type: "number",
    error: "",
    value: null,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_slimjimrecyclage,
  },

  vdl_detailssautresstructures: {
    type: "multiline",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_detailssautresstructures,
  },
  vdl_trajet: {
    type: "multiline",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_trajet,
  },

  vdl_typeaccessoirefeu: {
    type: "multipleoptions",
    error: "",
    value: [],
    choices: activityOptionSetChoices.vdl_typeaccessoirefeu,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_typeaccessoirefeu,
  },
  vdl_typeappareilcombustion: {
    type: "multipleoptions",
    error: "",
    value: [],
    choices: activityOptionSetChoices.vdl_typeappareilcombustion,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_typeappareilcombustion,
  },
  vdl_typeartistedefeu: {
    type: "multipleoptions",
    error: "",
    value: [],
    choices: activityOptionSetChoices.vdl_typeartistedefeu,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_typeartistedefeu,
  },
  vdl_typeactions: {
    type: "multipleoptions",
    error: "",
    value: [],
    choices: activityOptionSetChoices.vdl_typeactions,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_typeactions,
  },
  vdl_typecombustible: {
    type: "multipleoptions",
    error: "",
    value: [],
    choices: activityOptionSetChoices.vdl_typecombustible,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_typecombustible,
  },
  vdl_typedefeu: {
    type: "multipleoptions",
    error: "",
    value: [],
    choices: activityOptionSetChoices.vdl_typedefeu,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_typedefeu,
  },

  vdl_typedeplacement: {
    type: "options",
    error: "",
    value: null,
    choices: activityOptionSetChoices.vdl_typedeplacement,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_typedeplacement,
  },
  vdl_typefermeturederue: {
    type: "options",
    error: "",
    value: null,
    choices: activityOptionSetChoices.vdl_typefermeturederue,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_typefermeturederue,
  },

  vdl_typevoiepublique: {
    type: "multipleoptions",
    error: "",
    value: [],
    choices: activityOptionSetChoices.vdl_typevoiepublique,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_typevoiepublique,
  },
  vdl_typepyro: {
    type: "multipleoptions",
    error: "",
    value: [],
    choices: activityOptionSetChoices.vdl_typepyro,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_typepyro,
  },
  vdl_typeserviceeco: {
    type: "multipleoptions",
    error: "",
    value: [],
    choices: activityOptionSetChoices.vdl_typeserviceeco,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_typeserviceeco,
  },
  vdl_typesaffichages: {
    type: "multipleoptions",
    error: "",
    value: [],
    choices: activityOptionSetChoices.vdl_typesaffichages,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_typesaffichages,
  },
  vdl_typestructure: {
    type: "multipleoptions",
    error: "",
    value: [],
    choices: activityOptionSetChoices.vdl_typestructure,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_typestructure,
  },

  vdl_ventedemandeur: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_ventedemandeur,
  },
  vdl_ventetiers: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: activityDisplayNames.vdl_ventetiers,
  },
};

export { editActivityFields };
