import { Box, Dialog, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ClickableIcon from "../../../../../common/buttons/icons/ClickableIcon";
import { Close } from "@mui/icons-material";
import CustomButton from "../../../../../common/buttons/CustomButton";
import SimpleFields from "../../../../../form/SimpleForm/SimpleFields";
import {
  DropDownChoiceWithLabel,
  FormFields,
  QueryError,
} from "../../../../../../types";
import { SiteRow } from "../../../../../../data/Plateau/models.ts";
import {
  EditDemandPublicSpaceReservationFields,
  editDemandPublicSpaceReservationFields,
  editDemandRervationSections,
} from "../../../../../../data/Incident/forms/publicSpace/reservation";
import { IncidentPublicSpaceReservationRowNew } from "../../../../../../data/Incident/types/publicSpace/reservation";
import handleSimpleFormSubmit from "../../../../../form/SimpleForm/handleSimpleFormSubmit";
import { useSnackbar } from "../../../../../../hooks/useSnackbar";
import CustomDialogHeader from "../../../../../dialog/CustomDialogHeader/CustomDialogHeader";
import {
  buildFormObj,
  buildPartialFormObj,
} from "../../../../../form/SimpleForm/buildForm";
import { getSites } from "../../../../../../data/Plateau/queries";
import { useQuery } from "@tanstack/react-query";
import SectionLoading from "../../../../../common/loading/SectionLoading";
import QueryErrorMessage from "../../../../../common/error/QueryErrorMessage";
import QueryErrorMessageInner from "../../../../../common/error/QueryErrorMessageInner";
import ReservationsDialogInner from "./ReservationsDialogInner";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addReservation(newReservation: IncidentPublicSpaceReservationRowNew): void;
};

const ReservationsDialog = ({ open, setOpen, addReservation }: Props) => {
  const { data, error, isLoading, isFetching } = useQuery<
    SiteRow[],
    QueryError
  >({
    queryKey: ["Sites"],
    queryFn: () => getSites(),
    staleTime: 600000,
    gcTime: 600000,
  });

  if (isLoading)
    return (
      <Dialog open={open} maxWidth="md" fullWidth>
        <CustomDialogHeader title="Nouvelle réservation" setOpen={setOpen} />
        <Box padding="16px">
          <SectionLoading />
        </Box>
      </Dialog>
    );

  if (!data || error || data.length === 0)
    return (
      <Dialog open={open} maxWidth="md" fullWidth>
        <CustomDialogHeader title="Nouvelle réservation" setOpen={setOpen} />
        <Box padding="16px">
          <QueryErrorMessageInner error={error} />
        </Box>
      </Dialog>
    );

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <CustomDialogHeader title="Nouvelle réservation" setOpen={setOpen} />
      <ReservationsDialogInner sites={data} addReservation={addReservation}/>
    </Dialog>
  );
};

export default ReservationsDialog;
