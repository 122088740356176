import { DisplayNames } from "../../types";
import { ActivityRow } from "./models";

const activityDisplayNames: DisplayNames<ActivityRow> = {
    vdl_activite: "",
    vdl_activite_evenementid: "",
    vdl_adressetoilettes: "",
    vdl_attestationassurancerespo: "",
    vdl_attestationfournisseurequipement: "",
    vdl_attestationoperateur: "",
    vdl_bacroulantbrun240l: "",
    vdl_datesethoraire: "",
    vdl_document1: "",
    vdl_document1_name: "",
    vdl_document2: "",
    vdl_document2_name: "",
    vdl_document3: "",
    vdl_document3_name: "",
    vdl_duobacsroulants240l: "",
    vdl_duobarils205l: "",
    vdl_debut: "Début",
    vdl_detailsaffichages: "",
    vdl_detailsspecifiques: "",
    vdl_detailsstructures: "",
    vdl_fin: "Fin",
    vdl_adressesite: "",
    vdl_nombreparticipants: "",
    vdl_nopointsdevente: "",
    vdl_nbtoilette_mobilitereduite: "",
    vdl_nbtoilette: "",
    vdl_profitsactivite: "",
    vdl_precisionlieusoutieneco: "",
    vdl_detailssautresaffichages: "",
    vdl_raison: "",
    vdl_details: "Renseignements additionnels",
    vdl_ruedirection: "",
    vdl_slimjimcompost: "",
    vdl_slimjimnoir90l: "",
    vdl_slimjimrecyclage: "",
    vdl_detailssautresstructures: "",
    vdl_trajet: "",
    vdl_typeaccessoirefeu: "",
    vdl_typeappareilcombustion: "",
    vdl_typeartistedefeu: "",
    vdl_typeactions: "",
    vdl_typecombustible: "",
    _vdl_typedactivite_value: "Type d'activité",
    _vdl_typedactivite_value_Formatted: "Type d'activité",
    vdl_typedefeu: "",
    vdl_typedeplacement: "",
    vdl_typefermeturederue: "",
    vdl_typevoiepublique: "",
    vdl_typepyro: "",
    vdl_typeserviceeco: "",
    vdl_typesaffichages: "",
    vdl_typestructure: "",
    vdl_ventedemandeur: "",
    vdl_ventetiers: "",
    statuscode: "Statut"
};

export default activityDisplayNames;
