import { Box } from "@mui/material";
import { IncidentFilmingRow } from "../../../../../../data/Incident/models";
import {
  filmingDisplayNames,
} from "../../../../../../data/Incident/displayNames";
import DisplayFieldCard from "../../../../../common/cards/DisplayFieldCard";
import { dateStringToShortStringUTC, getStringFromMultipleOptionSet } from "../../../../../../data/common/fieldToString";
import SpecialEvents from "../SpecialEvents";
import { incidentOptionSetsChoices } from "../../../../../../data/Incident/optionSetChoices";

type Props = {
  demand: IncidentFilmingRow;
};

const Filming = ({ demand }: Props) => {
  const details = demand.vdl_detailsdelademande;
  return (
    <Box display="grid" gap="16px">
      <SpecialEvents details={demand.vdl_detailsdelademande} />
      <DisplayFieldCard
        title="Informations sur le tournage"
        fields={[
          {
            label: filmingDisplayNames.vdl_titredelaproduction,
            value: details.vdl_titredelaproduction,
            type: "string",
          },
          {
            label: filmingDisplayNames.vdl_naturedutournage,
            value: getStringFromMultipleOptionSet(details.vdl_naturedutournage, incidentOptionSetsChoices.vdl_naturedutournage),
            type: "string",
          },
          {
            label: filmingDisplayNames.vdl_tournageetudiant,
            value: details.vdl_tournageetudiant,
            type: "check",
          },
          {
            label: filmingDisplayNames.vdl_resumeduscenarioenquelquesmots,
            value: details.vdl_resumeduscenarioenquelquesmots,
            type: "string",
          },
          {
            label: filmingDisplayNames.vdl_nombredevehiculesdeproductionetparticuliers,
            value: details.vdl_nombredevehiculesdeproductionetparticuliers?.toString(),
            type: "string",
          },

          {
            label: filmingDisplayNames.vdl_cubes,
            value: details.vdl_cubes?.toString(),
            type: "string",
          },
          {
            label: filmingDisplayNames.vdl_roulottes,
            value: details.vdl_roulottes?.toString(),
            type: "string",
          },
          {
            label: filmingDisplayNames.vdl_voituresparticuliers,
            value: details.vdl_voituresparticuliers?.toString(),
            type: "string",
          },
          {
            label: filmingDisplayNames.vdl_autresprecisez,
            value: details.vdl_autresprecisez,
            type: "string",
          },

          {
            label: filmingDisplayNames.vdl_descriptiondesequipementsaetreinstalles,
            value: details.vdl_descriptiondesequipementsaetreinstalles,
            type: "string",
          }
        ]}
        minColWidth={260} columns={1}
        />
      <DisplayFieldCard
        title="Engagements de la maison de production"
        fields={[
          {
            label: filmingDisplayNames.vdl_engagementloisetreglementtournage,
            value: details.vdl_engagementloisetreglementtournage,
            type: "check",
          },
          {
            label: filmingDisplayNames.vdl_respectdesconditionsdusoutienmunicipal,
            value: details.vdl_respectdesconditionsdusoutienmunicipal,
            type: "check",
          },
          {
            label: filmingDisplayNames.vdl_envoidesdocuments2semmaximumavantletournage,
            value: details.vdl_envoidesdocuments2semmaximumavantletournage,
            type: "check",
          },
          {
            label: filmingDisplayNames.vdl_engagementapayerlafacture,
            value: details.vdl_engagementapayerlafacture,
            type: "check",
          },
          {
            label: filmingDisplayNames.vdl_sengageafournirlesjustificatifssurdemande,
            value: details.vdl_sengageafournirlesjustificatifssurdemande,
            type: "check",
          },
        ]}
        minColWidth={260}
        columns={1}
      />
    </Box>
  );
};

export default Filming;
