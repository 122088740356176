import {
  CheckBoxInput,
  FormFields,
  FormSection,
  PhoneInput,
  TextInput,
  DropDownOptionSetInput,
  EmailInput,
  OptionSetInput,
  AddressInput,
} from "../../../types";
import { globalOptionSetsChoices } from "../../globalOptionSetChoices";
import accountDisplayNames from "../displayNames";

import { vdl_secteurChoices } from "./optionSetChoices";

export type EditAccountFields = {
  // Step 1 Info générales
  name: TextInput;
  vdl_autresnomorganisme: TextInput;
  telephone1: PhoneInput;
  emailaddress1: EmailInput;
  websiteurl: TextInput;
  vdl_pagefacebook: TextInput;

  // Step 2 Info adresse du siege social
  vdl_secteursiegesocial: OptionSetInput;
  siegeSocialAddress: AddressInput;
  vdl_adresseidentique: CheckBoxInput;
  correspondanceAddress: AddressInput;
};

const sectionGeneralInfo: FormSection<EditAccountFields> = {
  fields: [
    "name",
    "vdl_autresnomorganisme",
    "emailaddress1",
    "telephone1",
    "websiteurl",
    "vdl_pagefacebook",
  ],
  title: "Informations Générales",
};

const sectionSiegeSocialInfo: FormSection<EditAccountFields> = {
  fields: ["vdl_secteursiegesocial", "siegeSocialAddress"],
  title: "Adresse du Siège Social",
};

const sectionCorrespondanceInfo: FormSection<EditAccountFields> = {
  fields: ["vdl_adresseidentique", "correspondanceAddress"],
  title: "Adresse de correspondance",
};

const editAccountSections: FormSection<EditAccountFields>[] = [
  sectionGeneralInfo,
  sectionSiegeSocialInfo,
  sectionCorrespondanceInfo,
];

const editAccountFields: EditAccountFields = {
  // info generales
  name: {
    type: "text",
    value: "",
    error: "",
    required: true,
    label: accountDisplayNames.name,
  },

  vdl_autresnomorganisme: {
    type: "text",
    value: "",
    error: "",
    required: false,
    label: accountDisplayNames.vdl_autresnomorganisme,
  },

  telephone1: {
    type: "phone",
    value: "",
    error: "",
    required: true,
    label: accountDisplayNames.telephone1,
  },

  emailaddress1: {
    type: "email",
    value: "",
    error: "",
    required: true,
    label: accountDisplayNames.emailaddress1,
  },

  websiteurl: {
    type: "text",
    value: "",
    error: "",
    required: false,
    label: accountDisplayNames.websiteurl,
  },

  vdl_pagefacebook: {
    type: "text",
    value: "",
    error: "",
    required: false,
    label: accountDisplayNames.vdl_pagefacebook,
  },

  // info adresse du siege social
  vdl_secteursiegesocial: {
    type: "options",
    error: "",
    value: null,
    choices: globalOptionSetsChoices.vdl_secteursiegesocial,
    required: true,
    fillLine: true,
    label: accountDisplayNames.vdl_secteursiegesocial,
  },

  siegeSocialAddress: {
    type: "address",
    value: null,
    error: "",
    required: false,
    fillLine: true,
    label: "Recherche adresse",
  },
  vdl_adresseidentique: {
    type: "checkbox",
    value: false,
    error: "",
    required: false,
    fillLine: true,
    label: accountDisplayNames.vdl_adresseidentique,
    onChangeEvent: onSameAddressChange,
  },
  correspondanceAddress: {
    type: "address",
    value: null,
    error: "",
    required: false,
    fillLine: true,
    label: "Recherche",
    disabledCondition: isSameAddress,
    requiredCondition: {
      validator: (fields) => !isSameAddress(fields),
      error: "Utiliser l'adresse du siège social ou entrer une adresse",
    },
  },
};

function isSameAddress(fields: FormFields): boolean {
  const editFields = fields as EditAccountFields;
  return editFields.vdl_adresseidentique.value;
}
function onSameAddressChange(fields: FormFields): FormFields | undefined {
  try {
    const addressFields = <EditAccountFields>fields;
    let _fields: Partial<EditAccountFields> = {};
    _fields.correspondanceAddress = {
      ...addressFields.correspondanceAddress,
      error: "",
      value: null,
    };

    return _fields;
  } catch (e) {
    return undefined;
  }
}

const editAccountForm = {
  fields: editAccountFields,
  sections: editAccountSections,
};

export { editAccountForm, editAccountSections, editAccountFields };
