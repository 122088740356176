import { Box } from "@mui/material";
import { IncidentElectronicPanelsRow } from "../../../../../data/Incident/models";
import {
  electronicPanelsDisplayNames,
  websiteDisplayDisplayNames,
} from "../../../../../data/Incident/displayNames";
import DisplayFieldCard from "../../../../common/cards/DisplayFieldCard";
import { dateStringToShortStringUTC } from "../../../../../data/common/fieldToString";

type Props = {
  demand: IncidentElectronicPanelsRow;
};

const ElectronicPanel = ({ demand }: Props) => {
  const details = demand.vdl_detailsdelademande;
  return (
    <Box display="grid" gap="16px">
      <DisplayFieldCard
        title="Date d'affichage"
        fields={[
          {
            label: electronicPanelsDisplayNames.vdl_datededebutdelaffichage,
            value: dateStringToShortStringUTC(details.vdl_datededebutdelaffichage),
            type: "string",
          },
          {
            label: electronicPanelsDisplayNames.vdl_datedefindelaffichage,
            value: dateStringToShortStringUTC(details.vdl_datedefindelaffichage),
            type: "string",
          },
        ]}
        columns={2}
        minColWidth={300}
      />
       <DisplayFieldCard
        title="Détails"
        fields={[
          {
            label: electronicPanelsDisplayNames.vdl_quoi,
            value: details.vdl_quoi,
            type: "string",
          },
          {
            label: electronicPanelsDisplayNames.vdl_qui,
            value: details.vdl_qui,
            type: "string",
          },
          // {
          //   label: electronicPanelsDisplayNames.vdl_quanddatesetheures,
          //   value: details.vdl_quanddatesetheures,
          //   type: "string",
          // },
          {
            label: electronicPanelsDisplayNames.vdl_ou,
            value: details.vdl_ou,
            type: "string",
          },
          {
            label: electronicPanelsDisplayNames.vdl_telephonepourinformationsurlevenement,
            value: details.vdl_telephonepourinformationsurlevenement,
            type: "string",
          },
        ]}
        columns={1}
        minColWidth={300}
      />
    </Box>
  );
};

export default ElectronicPanel;
