import React, { useState } from "react";
import { DocumentFile } from "../../../data/Document/models";
import {
  Box,
  BoxProps,
  Button,
  CircularProgress,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import LinkIcon from "../../common/buttons/icons/LinkIcon";
import ClickableIcon from "../../common/buttons/icons/ClickableIcon";
import { Download } from "@mui/icons-material";
import { ErrorType, QueryError } from "../../../types";
import { useSnackbar } from "../../../hooks/useSnackbar";
import fetchMsal from "../../../data/fetchMsal";
import DocumentIcon from "../../common/icons/DocumentIcon";
import XlsIcon from "../../common/icons/XlsIcon";
import DocxIcon from "../../common/icons/DocxIcon";
import DocIcon from "../../common/icons/DocIcon";
import PdfIcon from "../../common/icons/PdfIcon";
import ImageIcon from "../../common/icons/ImageIcon";

type Props = {
  file: DocumentFile;
};

const getExtension = (filename: string | undefined) => {
  return filename ? filename.split(".").pop() : "";
};

const renderIcon = (file: DocumentFile) => {
  
  switch (getExtension(file.name)?.toLowerCase()) {
    case "docx":
      return <DocxIcon />;
    case "doc":
      return <DocIcon />;
    case "pdf":
      return <PdfIcon />;
    case "xls":
    case "xlsx":
      return <XlsIcon />;
    case "png":
    case "jpeg":
    case "svg":
    case "gif":
    case "png":
      return <ImageIcon />;
    default:
      return <DescriptionIcon />;
  }
};
const DownloadableFile = ({ file }: Props) => {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const { showSnackbar, showErrorSnackbar } = useSnackbar();

  async function handleDownload() {
    try {
      setDownloadLoading(true);
      const endpoint = `${process.env.REACT_APP_API_BASE_URL}/download_file/${file.id}`;

      const result = await fetchMsal("GET", endpoint);

      if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

      if (result.status !== 200) {
        throw new QueryError(ErrorType.INTERNAL, await result.text());
      }

      const fileURL = window.URL.createObjectURL(await result.blob());

      const contentDisposition = result.headers.get("Content-Disposition");
      const fileName = contentDisposition
        ?.split("filename=")[1]
        .replace(/"/g, "");

      if (!fileName) return;

      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = fileName;
      document.body.appendChild(alink);
      alink.click();
      document.body.removeChild(alink);
      URL.revokeObjectURL(fileURL);
    } catch (error) {
      if (error instanceof QueryError) {
        showErrorSnackbar(
          error.type === ErrorType.FORBIDDEN ||
            error.type === ErrorType.UNAUTHORIZED
            ? "Vous n'avez pas accès à ce fichier'."
            : "Erreur dans le téléchargement du fichier.",
          "error",
          error
        );
      } else {
        showSnackbar("Erreur dans le téléchargement du fichier.", "error");
      }
    } finally {
      setDownloadLoading(false);
    }
  }
  return (
    <Box display="flex" flexDirection="row" gap={"8px"}>
      {renderIcon(file)}
      <Typography>{file.name}</Typography>
      {downloadLoading ? (
        <Box maxHeight={"1.5rem"} display="flex" alignItems="center">
          <CircularProgress size="1rem" />
        </Box>
      ) : (
        <IconButton
          onClick={handleDownload}
          sx={{
            fontSize: "1.0rem",
            padding: 0,
            paddingTop: "2px",
            "&:hover": {
              opacity: "0.7",
            },
          }}
          disableRipple={true}
        >
          <Download fontSize="inherit" />
        </IconButton>
      )}
    </Box>
  );
};

export default DownloadableFile;
