import React, { useState } from "react";
import {
  IncidentFilmingPatch,
  IncidentFilmingRow,
  IncidentPatch,
} from "../../../../../data/Incident/models";
import { NewDemandFields } from "../../../../../data/Incident/forms/dialog";
import EditDemandLayout from "../../EditDemandLayout";
import SimpleFields from "../../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../../hooks/useTabs";
import {
  EditDemandFilmingFields,
  editDemandFilmingFields,
  editDemandFilmingSections,
} from "../../../../../data/Incident/forms/specialEvents/filming/filiming";
import SpecialEvents from "../GeneralFields/SpecialEvents";
import {
  EditDemandSpecialEventsGeneralFields,
  editDemandSpecialEventsGeneralFields,
} from "../../../../../data/Incident/forms/specialEvents/specialEventsGeneral";
import {
  IncidentSpecialEventsDateEditing,
  IncidentSpecialEventsDatePatch,
} from "../../../../../data/Incident/types/specialEvents/dates";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import { buildForm } from "../../../../form/SimpleForm/buildForm";
import handleSimpleFormSrollToError from "../../../../form/SimpleForm/handleSimpleFormSubmitAndScroll";
import { validateDateString } from "../../../../../data/common/validateDateString";
import { parseAddress } from "../../../../../data/addressHelper";
import { dayjsToUtcString } from "../../../../../data/common/dayjsToUtcString";

type Props = {
  demand: IncidentFilmingRow;
  generalFields: NewDemandFields;
  update: (incident: IncidentPatch) => Promise<void>;
};

const Filming = ({ demand, generalFields, update }: Props) => {
  const details = demand.vdl_detailsdelademande;

  const [fieldsGeneral, setFieldsGeneral] =
    useState<NewDemandFields>(generalFields);

    const [specialEventFields, setSpecialEventFields] =
    useState<EditDemandSpecialEventsGeneralFields>(
      buildForm(editDemandSpecialEventsGeneralFields, {
        vdl_lieudelevenement: details.vdl_lieudelevenement
        ? {
            text: details.vdl_lieudelevenement,
            magicKey: "",
          }
        : null,
        vdl_precisionsurlelieu: details.vdl_precisionsurlelieu,
        vdl_siautreprecisezlieu: details.vdl_siautreprecisezlieu ?? "",
        vdl_encasdintemperieslevenementest:
          details.vdl_encasdintemperieslevenementest ?? [],
        vdl_siremisle: validateDateString(details.vdl_siremisle),
      })
    );
    
  const [fields, setFields] = useState<EditDemandFilmingFields>(
    buildForm(editDemandFilmingFields, {
      vdl_titredelaproduction: details.vdl_titredelaproduction ?? "",
      vdl_naturedutournage: details.vdl_naturedutournage ?? [],
      vdl_tournageetudiant: details.vdl_tournageetudiant ?? false,
      vdl_resumeduscenarioenquelquesmots:
        details.vdl_resumeduscenarioenquelquesmots ?? "",
      vdl_nombredevehiculesdeproductionetparticuliers:
        details.vdl_nombredevehiculesdeproductionetparticuliers,
      vdl_cubes: details.vdl_cubes,
      vdl_roulottes: details.vdl_roulottes,
      vdl_voituresparticuliers: details.vdl_voituresparticuliers,
      vdl_autresprecisez: details.vdl_autresprecisez ?? "",
      vdl_descriptiondesequipementsaetreinstalles:
        details.vdl_descriptiondesequipementsaetreinstalles ?? "",

      vdl_engagementloisetreglementtournage:
        details.vdl_engagementloisetreglementtournage ?? false,
      vdl_respectdesconditionsdusoutienmunicipal:
        details.vdl_respectdesconditionsdusoutienmunicipal ?? false,
      vdl_envoidesdocuments2semmaximumavantletournage:
        details.vdl_envoidesdocuments2semmaximumavantletournage ?? false,
      vdl_engagementapayerlafacture:
        details.vdl_engagementapayerlafacture ?? false,
      vdl_sengageafournirlesjustificatifssurdemande:
        details.vdl_sengageafournirlesjustificatifssurdemande ?? false,
    })
  );
  const [dates, setDates] = useState<IncidentSpecialEventsDateEditing[]>(
    details.dates
  );
  const { activeTab, changeTab } = useTabs();
  const { showSnackbar } = useSnackbar();

  const onSubmit = async () => {
    const errors = [...handleSimpleFormSubmit(fields, setFields), ...handleSimpleFormSubmit(specialEventFields, setSpecialEventFields)];

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      await handleSimpleFormSrollToError(errors[errors.length - 1]);
      return;
    }


    const addressText = specialEventFields.vdl_lieudelevenement.value?.text;
    if (!addressText) {
      showSnackbar("L'adresse est invalide.", "error");
      return;
    }


    const parsedAddress = parseAddress(addressText);
    if (!parsedAddress) {
      showSnackbar("L'adresse est invalide.", "error");
      return;
    }

    // console.log(parsedAddress, "parsedAddress");

    const _demand: IncidentFilmingPatch = {
      title: fieldsGeneral.title.value,
      _vdl_contactprincipal_value: fieldsGeneral.vdl_contactprincipal.value,
      idtypedeservice: demand.idtypedeservice,
      vdl_detailsdelademande: {

        vdl_lieudelevenement: addressText,
        
        vdl_rue1: parsedAddress.street,
        vdl_rue2: parsedAddress.apartment,
        vdl_ville: parsedAddress.city, 
        vdl_province: parsedAddress.province,
        vdl_codepostal: parsedAddress.postalCode,
        vdl_pays: parsedAddress.country,

        vdl_precisionsurlelieu:
          specialEventFields.vdl_precisionsurlelieu.value ?? undefined,
        vdl_siautreprecisezlieu:
          specialEventFields.vdl_siautreprecisezlieu.value,
        vdl_encasdintemperieslevenementest:
          specialEventFields.vdl_encasdintemperieslevenementest.value,
        vdl_siremisle: dayjsToUtcString(specialEventFields.vdl_siremisle.value),
        dates: [],

        vdl_titredelaproduction: fields.vdl_titredelaproduction.value,
        vdl_naturedutournage: fields.vdl_naturedutournage.value,
        vdl_tournageetudiant: fields.vdl_tournageetudiant.value,
        vdl_resumeduscenarioenquelquesmots:
          fields.vdl_resumeduscenarioenquelquesmots.value,
        vdl_nombredevehiculesdeproductionetparticuliers:
          fields.vdl_nombredevehiculesdeproductionetparticuliers.value,
        vdl_cubes: fields.vdl_cubes.value,
        vdl_roulottes: fields.vdl_roulottes.value,
        vdl_voituresparticuliers: fields.vdl_voituresparticuliers.value,
        vdl_autresprecisez: fields.vdl_autresprecisez.value,
        vdl_descriptiondesequipementsaetreinstalles:
          fields.vdl_descriptiondesequipementsaetreinstalles.value,

        vdl_engagementloisetreglementtournage:
          fields.vdl_engagementloisetreglementtournage.value,
        vdl_respectdesconditionsdusoutienmunicipal:
          fields.vdl_respectdesconditionsdusoutienmunicipal.value,
        vdl_envoidesdocuments2semmaximumavantletournage:
          fields.vdl_envoidesdocuments2semmaximumavantletournage.value,
        vdl_engagementapayerlafacture:
          fields.vdl_engagementapayerlafacture.value,
        vdl_sengageafournirlesjustificatifssurdemande:
          fields.vdl_sengageafournirlesjustificatifssurdemande.value,
      },
    };

    const datesUpdate: IncidentSpecialEventsDatePatch[] = [];

    let validDatesCount = 0;

    dates.forEach((date) => {
      if ("vdl_datesid" in date) {
        if ("delete" in date) {
          datesUpdate.push({
            vdl_datesid: date.vdl_datesid,
            delete: true,
          });
        } else {
          validDatesCount++;
        }
      } else {
        validDatesCount++;
        datesUpdate.push({
          ...date,
        });
      }
    });

    if (validDatesCount === 0) {
      showSnackbar(
        "Veuillez entrer au moins une date avant d'enregistrer la demande",
        "error"
      );
      return;
    }

    _demand.vdl_detailsdelademande.dates = datesUpdate;

    await update(_demand);
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_typededemande.vdl_lienhautduformulaireportail}
      parentIncidentId={demand._parentcaseid_value}
    >
      <SpecialEvents
        fields={specialEventFields}
        setFields={setSpecialEventFields}
        dates={dates}
        setDates={setDates}
      />
      <SimpleFields
        fields={fields}
        setFields={setFields}
        sections={editDemandFilmingSections}
        sectionsBackground="background.paper"
      />
    </EditDemandLayout>
  );
};

export default Filming;
