import { Box, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ActivityCard from "./ActivityCard";
import { HistoryRow } from "../../../../../../data/History/models";
import { useParams } from "react-router-dom";
import HistoricEventCardSkeletton from "./ActivityCardSkeletton";
import ErrorMessage from "../../../../../common/error/ErrorMessage";
import CustomButton from "../../../../../common/buttons/CustomButton";
import NewMessageDialog from "./NewMessageDialog/NewMessageDialog";
import { IncidentGeneralRow } from "../../../../../../data/Incident/models";
import { QueryError } from "../../../../../../types";
import { useQuery } from "@tanstack/react-query";
import { getIncidentHistory } from "../../../../../../data/History/queries";
import QueryErrorMessageInner from "../../../../../common/error/QueryErrorMessageInner";
import PrivateComponent from "../../../../../common/auth/PrivateComponent";
import useUser from "../../../../../../hooks/useUserData";

type Props = {
  demand: IncidentGeneralRow;
};

const HistoricSection = ({ demand }: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { id } = useParams();
  const { userData } = useUser();

  const { data, error, isLoading, isFetching } = useQuery<
    HistoryRow[],
    QueryError
  >({
    queryKey: ["Demand", id, "History"],
    queryFn: () => getIncidentHistory(id),
    staleTime: 30000,
    gcTime: 30000,
  });

  if (isLoading)
    return (
      <Box>
        <Typography
          variant="h6"
          fontWeight="bold"
          color="primary"
          paddingBottom="16px"
        >
          Historique
        </Typography>
        {Array(2)
          .fill(0)
          .map((_, idx) => (
            <HistoricEventCardSkeletton key={idx} />
          ))}
      </Box>
    );

  if (!data || error || !id)
    return (
      <Box display="grid" gap="16px" paddingBottom="16px">
        <Typography variant="h6" fontWeight="bold" color="primary">
          Historique
        </Typography>
        <QueryErrorMessageInner error={error} />
      </Box>
    );

  return (
    <Box id="historique">
      {dialogOpen && (
        <NewMessageDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          demandid={id}
        />
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        gap="16px"
        paddingBottom="16px"
      >
        <Typography variant="h6" fontWeight="bold" color="primary">
          Historique
        </Typography>
        <PrivateComponent
          roleMinimum={
            demand._vdl_contactprincipal_value === userData.contactid
              ? "Usager"
              : "Administrateur"
          }
        >
          <CustomButton primary onClick={() => setDialogOpen(true)}>
            Ajouter une information
          </CustomButton>
        </PrivateComponent>
      </Box>

      {data.map((activity) => (
        <ActivityCard activity={activity} key={activity.activityid} />
      ))}
    </Box>
  );
};

export default HistoricSection;
