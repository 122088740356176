import dayjs, { Dayjs } from "dayjs";
import {
  CheckBoxInput,
  CommentInput,
  DateInput,
  DropDownChoice,
  DropDownOptionSetInput,
  FileInput,
  FormFields,
  FormSection,
  OptionSetChoices,
  OptionSetInput,
  TextInput,
} from "../../../types";
import { prismeDisplayNames } from "../displayNames";
import { incidentOptionSetsChoices } from "../optionSetChoices";

export type EditDemandPrismeFields = {
  vdl_activiteapromouvoir: TextInput;
  vdl_nombredepanneaux: OptionSetInput;
  vdl_datedelactivite: DateInput;
  vdl_datededebutdelevenement: DateInput;
  vdl_datedefindelevenement: DateInput;
  vdl_autoroute25bouldelaconcordeestsite3: CheckBoxInput;
  vdl_monteedumoulinleparcdumoulinsite10: CheckBoxInput;
  vdl_bouldeslaurentidesboulsaintmartinsite1: CheckBoxInput;
  vdl_bouldeslaurentidesboulcartiersite2: CheckBoxInput;
  vdl_boullecorbusierbouldusouvenirsite8: CheckBoxInput;
  vdl_bouldelaconcordebouldelavenirsite9: CheckBoxInput;
  vdl_boulcurelabelleboullevesquesite4: CheckBoxInput;
  vdl_boularthursauvebouldagenaisouestsite6: CheckBoxInput;
  vdl_boulsamsonavjosaphatdemerssite11: CheckBoxInput;
  vdl_bouldeslaurentidesbouldagenaisestsite5: CheckBoxInput;
  vdl_piecejointe: FileInput;
};

const editDemandPrismeFields: EditDemandPrismeFields = {
  vdl_activiteapromouvoir: {
    type: "text",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    label: prismeDisplayNames.vdl_activiteapromouvoir,
  },
  vdl_nombredepanneaux: {
    type: "options",
    error: "",
    value: null,
    required: true,
    fillLine: true,
    choices: incidentOptionSetsChoices.vdl_nombredepanneaux,
    label: prismeDisplayNames.vdl_nombredepanneaux,
  },
  vdl_piecejointe: {
    type: "file",
    error: "",
    value: null,
    required: true,
    fillLine: true,
    accept: [".png", ".jpg", ".PNG", ".jpeg"],
    label: prismeDisplayNames.vdl_piecejointe,
  },
  vdl_datedelactivite: {
    type: "date",
    error: "",
    value: null,
    required: true,
    disablePast: true,
    label: prismeDisplayNames.vdl_datedelactivite,
  },
  vdl_datededebutdelevenement: {
    type: "date",
    error: "",
    value: null,
    required: true,
    disablePast: true,
    label: prismeDisplayNames.vdl_datededebutdelevenement,
    onChangeEvent: (fields) =>
      onDateDebutChange(fields as EditDemandPrismeFields),
  },
  vdl_datedefindelevenement: {
    type: "date",
    error: "",
    value: null,
    required: true,
    disablePast: true,
    disabledCondition: isDateFinDisabled,
    label: prismeDisplayNames.vdl_datedefindelevenement,
    minDate: (fields: FormFields) =>
      fields.vdl_datededebutdelevenement.value
        ? (fields.vdl_datededebutdelevenement.value as Dayjs)
        : undefined,
    maxDate: (fields: FormFields) => {
      const startDate = fields.vdl_datededebutdelevenement.value
        ? (fields.vdl_datededebutdelevenement.value as Dayjs)
        : undefined;
      if (startDate) {
        const startDatePlusThreeWeeks = dayjs(startDate).add(3, "week");
        return startDatePlusThreeWeeks.isValid()
          ? startDatePlusThreeWeeks
          : undefined;
      } else {
        return undefined;
      }
    },
  },
  vdl_autoroute25bouldelaconcordeestsite3: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: prismeDisplayNames.vdl_autoroute25bouldelaconcordeestsite3,
  },
  vdl_monteedumoulinleparcdumoulinsite10: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: prismeDisplayNames.vdl_monteedumoulinleparcdumoulinsite10,
  },
  vdl_bouldeslaurentidesboulsaintmartinsite1: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: prismeDisplayNames.vdl_bouldeslaurentidesboulsaintmartinsite1,
  },
  vdl_bouldeslaurentidesboulcartiersite2: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: prismeDisplayNames.vdl_bouldeslaurentidesboulcartiersite2,
  },
  vdl_boullecorbusierbouldusouvenirsite8: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: prismeDisplayNames.vdl_boullecorbusierbouldusouvenirsite8,
  },
  vdl_bouldelaconcordebouldelavenirsite9: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: prismeDisplayNames.vdl_bouldelaconcordebouldelavenirsite9,
  },
  vdl_boulcurelabelleboullevesquesite4: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: prismeDisplayNames.vdl_boulcurelabelleboullevesquesite4,
  },
  vdl_boularthursauvebouldagenaisouestsite6: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: prismeDisplayNames.vdl_boularthursauvebouldagenaisouestsite6,
  },
  vdl_boulsamsonavjosaphatdemerssite11: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: prismeDisplayNames.vdl_boulsamsonavjosaphatdemerssite11,
  },
  vdl_bouldeslaurentidesbouldagenaisestsite5: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    label: prismeDisplayNames.vdl_bouldeslaurentidesbouldagenaisestsite5,
  },
};

function isDateFinDisabled(fields: FormFields): boolean {
  try {
    const _fields2 = <EditDemandPrismeFields>fields;
    return _fields2.vdl_datededebutdelevenement.value === undefined;
  } catch (e) {
    return true;
  }
}

function onDateDebutChange(
  fields: EditDemandPrismeFields
): Partial<EditDemandPrismeFields> | undefined {
  try {
    return {
      vdl_datedefindelevenement: {
        ...fields.vdl_datedefindelevenement,
        value:
          fields.vdl_datedefindelevenement.value &&
          fields.vdl_datedefindelevenement.value.isBefore(
            fields.vdl_datededebutdelevenement.value as Dayjs
          )
            ? null
            : fields.vdl_datedefindelevenement.value,
      },
    };
  } catch (e) {
    return undefined;
  }
}

const editDemandPrismeActivitySection: FormSection<EditDemandPrismeFields> = {
  fields: ["vdl_activiteapromouvoir", "vdl_datedelactivite"],
  title: "Activité",
};
const editDemandPrismeDisplayPeriodSection: FormSection<EditDemandPrismeFields> =
  {
    fields: ["vdl_datededebutdelevenement", "vdl_datedefindelevenement"],
    title: "Période d'affichage",
    description: "Maximum 3 semaines",
  };

const editDemandPrismePrecisionsSection: FormSection<EditDemandPrismeFields> = {
  fields: [
    "vdl_piecejointe",
    "vdl_nombredepanneaux",
    "vdl_bouldeslaurentidesboulsaintmartinsite1",
    "vdl_bouldeslaurentidesboulcartiersite2",
    "vdl_autoroute25bouldelaconcordeestsite3",
    "vdl_boulcurelabelleboullevesquesite4",
    "vdl_bouldeslaurentidesbouldagenaisestsite5",
    "vdl_boularthursauvebouldagenaisouestsite6",
    "vdl_boullecorbusierbouldusouvenirsite8",
    "vdl_bouldelaconcordebouldelavenirsite9",
    "vdl_monteedumoulinleparcdumoulinsite10",
    "vdl_boulsamsonavjosaphatdemerssite11",
  ],
  title: "Précisions",
  description:
    "Vous devez fournir des panneaux imprimés au format de 8 pi (largeur) x 4 pi (hauteur) x 1/8 de pouce d'épaisseur.",
};
const editDemandPrismeSections: FormSection<EditDemandPrismeFields>[] = [
  editDemandPrismeActivitySection,
  editDemandPrismeDisplayPeriodSection,
  editDemandPrismePrecisionsSection,
];
export { editDemandPrismeFields, editDemandPrismeSections };
