import {
  Box,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import DisplayField from "../../../../../common/cards/DisplayField";
import CustomChip from "../../../../../common/misc/CustomChip";
import { demandDisplayNames } from "../../../../../../data/Incident/displayNames";
import { dateTimeStringToStringLocal } from "../../../../../../data/common/fieldToString";
import { IncidentGeneralRow } from "../../../../../../data/Incident/models";
import { incidentOptionSetsChoices } from "../../../../../../data/Incident/optionSetChoices";

type Props = {
  demand: IncidentGeneralRow;
};

const GeneralInfoSection = ({demand} : Props) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        gap="8px"
        paddingBottom="8px"
      >
        <Typography variant="h6" fontWeight="bold" color="primary">
          Informations générales
        </Typography>
        <CustomChip status={incidentOptionSetsChoices.statuscode[demand.statuscode]} text={incidentOptionSetsChoices.statuscode[demand.statuscode]} />
      </Box>

      <Box display="flex" flexDirection="column" gap="6px">
        <DisplayField
          displayName={demandDisplayNames.ticketnumber}
          value={demand.ticketnumber}
          horizontal={!sm}
          horizontalLabelWidthPercentage={40}
        />
        <DisplayField
          displayName={demandDisplayNames.title}
          value={demand.title}
          horizontal={!sm}
          horizontalLabelWidthPercentage={40}
        />
        <DisplayField
          displayName={demandDisplayNames.createdon}
          value={dateTimeStringToStringLocal(demand.createdon)}
          horizontal={!sm}
          horizontalLabelWidthPercentage={40}
        />
        <DisplayField
          displayName={demandDisplayNames.modifiedon}
          value={dateTimeStringToStringLocal(demand.modifiedon)}
          horizontal={!sm}
          horizontalLabelWidthPercentage={40}
        />
      </Box>
    </Box>
  );
};

export default GeneralInfoSection;
