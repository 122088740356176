import React from "react";
import { FormFields } from "../../../types";
import dayjs, { Dayjs } from "dayjs";
import clone from "just-clone";

const requiredFieldError = "Ce champ est requis";
const requiredConditionFieldError = "Ce champ est conditionnellement requis";
const lengthFieldError = "Ce champ a une limite de caractères";

const handleSimpleFormSubmit = <T extends FormFields>(
  fields: T,
  setFields: React.Dispatch<React.SetStateAction<T>>
) => {
  let errors: (keyof T)[] = [];

  let _fields = clone(fields);

  Object.entries(fields).forEach(([key, value]) => {
    let update = false;
    let _field = { ...value };
    let requiredConditionResult = false;
    if (value.requiredCondition) {
      update = true;
      _field.error = "";
      try {
        requiredConditionResult = value.requiredCondition.validator(fields);
      } catch (error) {
        requiredConditionResult = false;
      }
    }

    function dateHandle(
      date:
        | dayjs.Dayjs
        | ((fields: FormFields) => dayjs.Dayjs | undefined)
        | undefined
    ): Dayjs | undefined {
      try {
        if (date !== undefined) {
          if (typeof date === "function") {
            return date(fields);
          } else {
            return date;
          }
        }
        if (!dayjs.isDayjs(date)) return undefined;
      } catch (error) {
        return undefined;
      }
    }

    switch (value.type) {
      case "checkbox":
        if (
          (value.required || requiredConditionResult) &&
          value.value === false
        ) {
          _field.error =
            value.requiredCondition && requiredConditionResult
              ? value.requiredCondition.error ?? requiredConditionFieldError
              : requiredFieldError;
          errors.push(key);
          update = true;
        }
        break;
      case "text":
      case "multiline":
        if ((value.required || requiredConditionResult) && value.value === "") {
          _field.error =
            value.requiredCondition && requiredConditionResult
              ? value.requiredCondition.error ?? requiredConditionFieldError
              : requiredFieldError;
          errors.push(key);
          update = true;
        } else if (value.maxLength && value.value.length > value.maxLength) {
          _field.error = lengthFieldError;
          errors.push(key);
          update = true;
        } else if (value.validator && value.value !== "") {
          const error = value.validator(value.value);
          if (error) {
            _field.error = error;
            errors.push(key);
            update = true;
          }
        }
        break;
      case "phone":
      case "email":
        if ((value.required || requiredConditionResult) && value.value === "") {
          _field.error =
            value.requiredCondition && requiredConditionResult
              ? value.requiredCondition.error ?? requiredConditionFieldError
              : requiredFieldError;
          errors.push(key);
          update = true;
        }
        break;
      case "number":
        if (!value.value) {
          if (value.required || requiredConditionResult) {
            _field.error =
              value.requiredCondition && requiredConditionResult
                ? value.requiredCondition.error ?? requiredConditionFieldError
                : requiredFieldError;
            errors.push(key);
            update = true;
          }
        } else if (value.max !== undefined && value.value > value.max) {
          _field.error = `Veuillez entrer un chiffre plus petit que ${
            value.max + 1
          }`;
          errors.push(key);
          update = true;
        } else if (value.min !== undefined && value.value < value.min) {
          _field.error = `Veuillez entrer un chiffre plus grand que ${
            value.min - 1
          }`;
          errors.push(key);
          update = true;
        }
        break;
      case "radio":
        if ((value.required || requiredConditionResult) && !value.value) {
          _field.error =
            value.requiredCondition && requiredConditionResult
              ? value.requiredCondition.error ?? requiredConditionFieldError
              : requiredFieldError;
          errors.push(key);
          update = true;
        }
        break;
      case "autocomplete":
        if ((value.required || requiredConditionResult) && value.value === "") {
          _field.error =
            value.requiredCondition && requiredConditionResult
              ? value.requiredCondition.error ?? requiredConditionFieldError
              : requiredFieldError;
          errors.push(key);
          update = true;
        }
        break;
      case "datetime":
      case "date":
        const minHandled = dateHandle(value.minDate);
        const maxHandled = dateHandle(value.maxDate);
        const dateFormat =
          value.type === "datetime" ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD";

        if ((value.required || requiredConditionResult) && !value.value) {
          _field.error =
            value.requiredCondition && requiredConditionResult
              ? value.requiredCondition.error ?? requiredConditionFieldError
              : requiredFieldError;
          errors.push(key);
          update = true;
        } else if (
          minHandled &&
          value.value &&
          value.value.isBefore(minHandled)
        ) {
          _field.error = `Veuillez entrer une date ${
            value.type === "datetime" ? "postérieure ou égale à" : ""
          } ${minHandled.format(dateFormat)}`;
          errors.push(key);
          update = true;
        } else if (
          maxHandled &&
          value.value &&
          value.value.isAfter(maxHandled)
        ) {
          _field.error = `Veuillez entrer une date ${
            value.type === "datetime" ? "antérieure ou égale à" : ""
          } ${maxHandled.format(dateFormat)}`;
          errors.push(key);
          update = true;
        }
        break;
      case "time":
        if ((value.required || requiredConditionResult) && !value.value) {
          _field.error =
            value.requiredCondition && requiredConditionResult
              ? value.requiredCondition.error ?? requiredConditionFieldError
              : requiredFieldError;
          errors.push(key);
          update = true;
        }
        break;
      case "optionset":
      case "lookup":
        if ((value.required || requiredConditionResult) && !value.value) {
          _field.error =
            value.requiredCondition && requiredConditionResult
              ? value.requiredCondition.error ?? requiredConditionFieldError
              : requiredFieldError;
          errors.push(key);
          update = true;
        }
        break;
      case "options":
        if ((value.required || requiredConditionResult) && value.value === null) {
          _field.error =
            value.requiredCondition && requiredConditionResult
              ? value.requiredCondition.error ?? requiredConditionFieldError
              : requiredFieldError;
          errors.push(key);
          update = true;
        }
        break;
      case "currency":
        if ((value.required || requiredConditionResult) && value.value === "") {
          _field.error =
            value.requiredCondition && requiredConditionResult
              ? value.requiredCondition.error ?? requiredConditionFieldError
              : requiredFieldError;
          errors.push(key);
          update = true;
        } else if (value.value !== "" && isNaN(parseFloat(value.value))) {
          _field.error = "Veuillez entrer un montant valide";
          errors.push(key);
          update = true;
        }
        break;
      case "address":
        if ((value.required || requiredConditionResult) && !value.value) {
          _field.error =
            value.requiredCondition && requiredConditionResult
              ? value.requiredCondition.error ?? requiredConditionFieldError
              : requiredFieldError;
          errors.push(key);
          update = true;
        }
        break;
      case "file":
        if ((value.required || requiredConditionResult) && !value.value) {
          _field.error =
            value.requiredCondition && requiredConditionResult
              ? value.requiredCondition.error ?? requiredConditionFieldError
              : requiredFieldError;
          errors.push(key);
          update = true;
        }
        break;
      case "multiplefiles":
      case "multipleoptionset":
      case "multipleoptions":
        if (
          (value.required || requiredConditionResult) &&
          value.value.length === 0
        ) {
          _field.error =
            value.requiredCondition && requiredConditionResult
              ? value.requiredCondition.error ?? requiredConditionFieldError
              : requiredFieldError;
          errors.push(key);
          update = true;
        }
        break;
      case "comment":
        break;
    }
    if (update) {
      _fields = { ..._fields, [key]: _field };
    }
  });


  setFields(_fields);
  return errors;
};

export default handleSimpleFormSubmit;
