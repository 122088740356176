import { DisplayNames } from "../../types";
import { HistoryPost } from "./models";

const historyDisplayNames: DisplayNames<HistoryPost> = {
    subject: "Sujet",
    description: "Description",
    vdl_typedinformation: "Type d'information",
    files: "Pièces jointes"
};

export default historyDisplayNames;
