import React from "react";
import { DropDownLookUpInput } from "../../../types";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  IconButton,
} from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material";

type Props = {
  field: DropDownLookUpInput;
  fieldName: string;
  handleChange: (field: DropDownLookUpInput) => void;
  disabled: boolean;
};

const FormLookupInput = ({
  field,
  fieldName,
  handleChange,
  disabled,
}: Props) => {
  function handleInputChange(event: SelectChangeEvent) {
    const { value } = event.target;
    let _field = { ...field };
    if (field.required && value === "") {
      _field.error = "";
    }
    _field.value = value;
    handleChange(_field);
  }
  function handleEmpty() {
    let _field = { ...field };
    _field.value = null;
    handleChange(_field);
  }
  function handleBlur(
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) {
    let _field = { ...field };
    _field.error = "";
    if (field.required && (!field.value || field.value === "")) {
      _field.error = "Ce champ est requis";
    }
    handleChange(_field);
  }

  return (
    <FormControl
      variant="outlined"
      fullWidth
      required={field.required}
      disabled={disabled}
      error={!(field.error == "")}
    >
      <InputLabel id={fieldName}>{field.label}</InputLabel>
      <Select
        labelId={fieldName}
        name={fieldName}
        value={field.value ?? ""}
        onChange={handleInputChange}
        label={field.label}
        onBlur={handleBlur}
        endAdornment={
          field.value && field.clearable ? (
            <IconButton
              size="small"
              sx={{
                position: "absolute",
                right: 32,
                visibility: field.value ? "visible" : "hidden",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                handleEmpty();
              }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ) : null
        }
      >
        {(field.sortBy === undefined
          ? field.choices
          : field.choices.sort((a, b) => {
              if (field.sortBy === "int") {
                return parseInt(a.label) - parseInt(b.label);
              }

              const textA = a.label.toString().toLowerCase();
              const textB = b.label.toString().toLowerCase();
              return textA.localeCompare(textB, "fr", { sensitivity: "base" });
            })
        ).map((choice, idx) => (
          <MenuItem
            onClick={() =>
              choice.value === field.value ? handleEmpty() : null
            }
            key={idx}
            value={choice.value}
          >
            {choice.label}
          </MenuItem>
        ))}
      </Select>
      {field.error || field.helperText ? (
        <FormHelperText
          component={Box}
          dangerouslySetInnerHTML={{ __html: field.helperText ?? "" }}
        ></FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default FormLookupInput;
