import { Box, styled } from "@mui/material";
import React, { useState } from "react";
import SimpleFormSectionTitle from "./SimpleFormSectionTitle";
import FormFieldWrapper from "./FormFieldWrapper";
import {
  FormField,
  FormFields,
  FormSection,
  SectionCheckBoxInput,
} from "../../../types";
import { FormBox } from "./SimpleFormSections";

type Props<T extends FormFields> = {
  formData: T;
  handleChange: (key: string, field: FormField) => void;
  columns?: {
    count: number;
    minWidth: number;
  };
  section: FormSection<T>;
  sectionsBackground?: string;
  updateRefs?: boolean;
};

function SimpleFormSections<T extends FormFields>({
  formData,
  handleChange,
  columns,
  section,
  sectionsBackground,
  updateRefs,
}: Props<T>) {
  const Fields = section.fields.map((fieldKey, idx) => {
    const field = formData[fieldKey];
    const key = fieldKey.toString();
    return (
      <FormFieldWrapper
        columns={section.columns ? section.columns : columns}
        field={field}
        fields={formData}
        key={key}
        fieldName={key}
        updateRefs={updateRefs}
        disabledConditionResult={
          field.disabledCondition
            ? field.disabledCondition(formData)
            : undefined
        }
        handleChange={handleChange}
      />
    );
  });

  return (
    <FormBox
      bgcolor={sectionsBackground}
      padding={section.padding ? section.padding : `16px 16px ${sectionsBackground ? 24 : 16}px 16px`}
    >
      {section.title || section.description ? (
        <SimpleFormSectionTitle
          title={section.title}
          description={section.description}

        />
      ) : null}
      {Fields}
    </FormBox>
  );
}

export default SimpleFormSections;
