import { QueryError, ErrorType, OperationType } from "../../types";
import fetchMsal from "../fetchMsal";
import { HistoryPost, HistoryRow } from "./models";

const getIncidentHistory = async (
  incidentId?: string
): Promise<HistoryRow[]> => {
  try {
    if (!incidentId)
      throw new QueryError(
        ErrorType.NOT_FOUND,
        "Identifiant de la demande est invalide."
      );
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_history/${incidentId}`;

    const result = await fetchMsal("GET", endpoint);

    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    }

    return await result.json();
  } catch (e) {
    throw QueryError.TransformUnknown(
      e,
      "Historique de la demande",
      OperationType.RETRIEVEMULTIPLE
    );
  }
};

const createIncidentMessage = async (
  incidentId: string,
  history: HistoryPost
): Promise<string | undefined> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/send_message/${incidentId}`;
    const result = await fetchMsal("POST", endpoint, history);
    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    }

    return await result.text();
  } catch (e) {
    throw QueryError.TransformUnknown(e, "Message du portail", OperationType.CREATE);
  }
};

export { getIncidentHistory, createIncidentMessage };
