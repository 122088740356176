import { EntityOptionSetChoices } from "../../types";
type ActivityOptionSetChoices = EntityOptionSetChoices<
  | "vdl_typeaccessoirefeu"
  | "vdl_typeappareilcombustion"
  | "vdl_typeartistedefeu"
  | "vdl_typeactions"
  | "vdl_typecombustible"
  | "vdl_typedefeu"
  | "vdl_typedeplacement"
  | "vdl_typefermeturederue"
  | "vdl_typevoiepublique"
  | "vdl_typepyro"  
  | "vdl_typeserviceeco"
  | "vdl_typesaffichages"
  | "vdl_typestructure"
  | "statuscode"
>;

export const activityOptionSetChoices: ActivityOptionSetChoices = {
  vdl_typeaccessoirefeu: {
    948300000: "Flambeaux",
    948300001: "Lampions",
    948300002: "Lanternes",
    948300003: "Autres",
  },
  vdl_typeappareilcombustion: {
    948300000: "Génératrice",
    948300001: "Barbecue",
    948300002: "Appareil de cuisson",
    948300003: "Barbecue",
    948300004: "Chauffage d'appoint",
    948300005: "Autre",
  },
  vdl_typeartistedefeu: {
    948300000: "Jongleur",
    948300001: "Cracheur de feu",
    948300002: "Autre",
  },
  vdl_typeactions: {
    948300000: "Cascades",
    948300001: "Simulation de vol",
    948300002: "Simulation de bagarre",
    948300003: "Simulation d'enlèvement",
    948300004: "Autre",
  },
  vdl_typecombustible: {
    948300000: "Essence",
    948300001: "Gaz propane",
    948300002: "Charbon",
    948300003: "Autre",
  },
  vdl_typedefeu: {
    948300000: "Feu à ciel ouvert",
    948300001: "Feu de joie (plus de 1 m de diamètre)",
    948300002: "Feu de camp (moins de 1m de diamètre)",
    948300003: "Foyer / brasero",
  },
  vdl_typedeplacement: {
    948300000: "Marche/course",
    948300001: "Défilé",
    948300002: "Procession religieuse",
    948300003: "Randonnée à vélo (15 cycliste et plus)",
    948300004: "Véhicule hippomobile (calèche)",
  },
  vdl_typefermeturederue: {
    948300000: "Complète",
    948300001: "Partielle",
  },
  vdl_typevoiepublique: {
    948300000: "Rue",
    948300001: "Trottoir",
    948300002: "Pistes cyclables",
    948300003: "Autre",
  },
  vdl_typepyro: {
    948300000: "Feu d'artifice domestique",
    948300001: "Effets spéciaux pyrotechniques",
    948300002: "Effets spéciaux - autres",
    948300003: "Feu d'artifice grand déploiement",
  },
  vdl_typeserviceeco: {
    948300003: "Bacs roulants, Barils, ou Bacs Slim Jim",
    948300000:
      "Kiosque du service de l'environnement et de l'écocitoyenneté (1000 participants et plus seulement)",
    948300001: "Support à vélo avec chaînes et cadenas",
    948300002: "Service de planification et d'accompagnement",
  },  
  vdl_typesaffichages: {
    948300000: "Panneau(x) de signalisation",
    948300001: "Bannière(s)",
    948300002: "Fanions(s)",
    948300003: "Autre(s)",
  },
  vdl_typestructure: {
    948300000: "Chapiteau-marquise",
    948300001: "Structure(s) gonflable(s)",
    948300002: "Tyrolienne",
    948300003: "Scène mobile",
    948300004: "Autre(s)",
  },
  statuscode: {
    1: "En création",
    948300001: "Soumise",
    2: "Approuvée",
    948300002: "Refusée",
    948300003: "Annulée",
  },
};
