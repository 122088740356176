import { Box, Dialog, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ClickableIcon from "../../../../../common/buttons/icons/ClickableIcon";
import { Close } from "@mui/icons-material";
import CustomButton from "../../../../../common/buttons/CustomButton";
import SimpleFields from "../../../../../form/SimpleForm/SimpleFields";
import {
  DropDownChoiceWithLabel,
  FormFields,
  QueryError,
} from "../../../../../../types";
import { SiteRow } from "../../../../../../data/Plateau/models.ts";
import {
  EditDemandPublicSpaceReservationFields,
  editDemandPublicSpaceReservationFields,
  editDemandRervationSections,
} from "../../../../../../data/Incident/forms/publicSpace/reservation";
import { IncidentPublicSpaceReservationRowNew } from "../../../../../../data/Incident/types/publicSpace/reservation";
import handleSimpleFormSubmit from "../../../../../form/SimpleForm/handleSimpleFormSubmit";
import { useSnackbar } from "../../../../../../hooks/useSnackbar";
import CustomDialogHeader from "../../../../../dialog/CustomDialogHeader/CustomDialogHeader";
import {
  buildFormObj,
  buildPartialFormObj,
} from "../../../../../form/SimpleForm/buildForm";
import { getSites } from "../../../../../../data/Plateau/queries";
import { useQuery } from "@tanstack/react-query";
import SectionLoading from "../../../../../common/loading/SectionLoading";
import QueryErrorMessage from "../../../../../common/error/QueryErrorMessage";
import QueryErrorMessageInner from "../../../../../common/error/QueryErrorMessageInner";
import { dayjsToUtcString } from "../../../../../../data/common/dayjsToUtcString";

type Props = {
  sites: SiteRow[];
  addReservation(newReservation: IncidentPublicSpaceReservationRowNew): void;
};

const ReservationsDialogInner = ({ sites, addReservation }: Props) => {
  const [fields, setFields] = useState<EditDemandPublicSpaceReservationFields>(
    buildPartialFormObj(editDemandPublicSpaceReservationFields, {
      vdl_nomdusiteareserver: {
        value: null,
        onChangeEvent: onFormationChange,
        choices: sites.map((site) => {
          return {
            label: site.vdl_name,
            value: site.vdl_plateauxid,
          };
        }),
      },
    })
  );
  const { showSnackbar } = useSnackbar();

  function onFormationChange(fields: FormFields): FormFields | undefined {
    try {
      const _fields2 = fields as EditDemandPublicSpaceReservationFields;

      let _fields: Partial<EditDemandPublicSpaceReservationFields> = {};
      _fields.vdl_sousplateau = {
        ..._fields2.vdl_sousplateau,
        value: null,
        choices: [],
      };
      if (_fields2.vdl_nomdusiteareserver.value === null) {
        _fields.vdl_sousplateau.choices = [];
        return _fields;
      }

      const sous_plateaux = sites.find(
        (site) => _fields2.vdl_nomdusiteareserver.value === site.vdl_plateauxid
      );
      if (!sous_plateaux) return _fields;

      const plateauChoices = sous_plateaux.plateaux.map((plateau) => ({
        label: plateau.vdl_name,
        value: plateau.vdl_plateauxid,
      }));

      _fields.vdl_sousplateau.choices = plateauChoices;
      return _fields;
    } catch (e) {
      return undefined;
    }
  }

  function handleSubmit() {
    try {
      const errors = handleSimpleFormSubmit(fields, setFields);

      if (errors.length < 1) {
        addReservation({
          vdl_debutdelareservation:
            dayjsToUtcString(fields.vdl_debutdelareservation.value)??"",
          vdl_findelareservation:
            dayjsToUtcString(fields.vdl_findelareservation.value)??"",
          vdl_ndetelephoneprincipal: fields.vdl_ndetelephoneprincipal.value,
          vdl_prenom: fields.vdl_prenom.value,
          vdl_nom: fields.vdl_nom.value,
          vdl_nombredeparticipants: fields.vdl_nombredeparticipants.value ?? 0,
          _vdl_nomdusiteareserver_value:
            fields.vdl_nomdusiteareserver.value ?? "",
          _vdl_sousplateau_value: fields.vdl_sousplateau.value ?? "",
          _vdl_sousplateau_value_Formatted:
            fields.vdl_sousplateau.choices.find(
              (s) => s.value === fields.vdl_sousplateau.value
            )?.label ?? "",
          _vdl_nomdusiteareserver_value_Formatted:
            fields.vdl_nomdusiteareserver.choices.find(
              (s) => s.value === fields.vdl_sousplateau.value
            )?.label ?? "",
          vdl_titre: fields.vdl_titre.value ?? 948300000,
        });
      }
    } catch (e) {
      showSnackbar("Erreur dans l'ajout de la réservation", "error");
    }
  }

  return (
    <>
      <Box>
        <SimpleFields
          columns={{ count: 2, minWidth: 250 }}
          fields={fields}
          setFields={setFields}
          sections={editDemandRervationSections}
        />
      </Box>
      <Box
        padding="16px"
        display="flex"
        alignItems="center"
        justifyContent="end"
      >
        <CustomButton primary onClick={handleSubmit}>
          Ajouter
        </CustomButton>
      </Box>
    </>
  );
};

export default ReservationsDialogInner;
