import React, { useState } from "react";
import { ActivityRow } from "../../../../data/Activity/models";
import { ActivityTypeRow } from "../../../../data/ActivityType/models";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  styled,
} from "@mui/material";
import activityDisplayNames from "../../../../data/Activity/displayNames";
import DemandeCardNew from "../../Demands/DemandCard/DemandeCardNew";
import { Delete, Edit, ExpandMore } from "@mui/icons-material";
import {
  dateStringToLongWithDayStringUTC,
  dateStringToShortStringUTC,
  dateTimeStringToStringUTC,
  getStringFromMultipleOptionSet,
  getStringFromOptionSet,
} from "../../../../data/common/fieldToString";
import LinkIcon from "../../../common/buttons/icons/LinkIcon";
import ClickableIcon from "../../../common/buttons/icons/ClickableIcon";
import DisplayField from "../../../common/cards/DisplayField";
import { ActivityTypeFieldRow } from "../../../../data/ActivityTypeField/models";
import { activityOptionSetChoices } from "../../../../data/Activity/optionSetChoices";
import ActivityFormDialog from "./ActivityFormDialog";
import { QueryError } from "../../../../types";
import { deleteActivity } from "../../../../data/Activity/queries";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { useConfirm } from "../../../../hooks/useConfirmation";

type Props = {
  isEditable: boolean;
  activity: ActivityRow;
  activityType: ActivityTypeRow;
};

const CardBox = styled(Box)(({ theme }) => ({
  display: "grid",
  rowGap: "8px",
  columnGap: "16px",
  height: "100%",
  alignItems: "center",
  gridTemplateColumns: "1fr",
  gridTemplateRows: "auto auto auto",
  gridAutoFlow: "column",
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "1fr auto",
    gridTemplateRows: "auto auto",
  },
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "1fr 1fr auto",
    gridTemplateRows: "1fr",
  },
}));

const activityFieldToString = (
  activity: ActivityRow,
  field: ActivityTypeFieldRow
): string | null => {
  const value = activity[field.vdl_nominterne as keyof ActivityRow];
  const valueIsNull = value === null;
  switch (field.vdl_type) {
    case 948300000:
    case 948300001:
      if (typeof value === "string") {
        return value;
      }
      break;
    case 948300002:
      if (typeof value === "string") {
        return dateTimeStringToStringUTC(value);
      }
      break;
    case 948300003:
      if (typeof value === "string") {
        return dateStringToShortStringUTC(value);
      }
      break;
    case 948300004:
    case 948300005:
      if (typeof value === "number") {
        return value.toString();
      }
      break;
    case 948300005:
      if (typeof value === "number") {
        return value.toString();
      }
      break;
    case 948300006:
      if (valueIsNull || typeof value === "boolean") {
        return value ? "Oui" : "Non";
      }
      break;
    case 948300008: //File
      if (typeof value === "string") {
        const fileName =
          activity[(field.vdl_nominterne + "_name") as keyof ActivityRow];
        if (typeof fileName === "string") return fileName;
      }
      break;
    case 948300007: //Options
      if (typeof value === "number") {
        const choices =
          activityOptionSetChoices[
            field.vdl_nominterne as keyof typeof activityOptionSetChoices
          ];
        if (choices !== undefined && choices !== null) {
          return getStringFromOptionSet(value, choices);
        }
      }
      break;
    case 948300009: //Options - plusieurs choix
      if (Array.isArray(value)) {
        const choices =
          activityOptionSetChoices[
            field.vdl_nominterne as keyof typeof activityOptionSetChoices
          ];
        if (choices !== undefined && choices !== null) {
          return getStringFromMultipleOptionSet(value, choices);
        }
      }
      break;
    default:
      return null;
  }
  return null;
};

const ActivityCard = ({ activity, activityType, isEditable }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [formOpenned, setFormOpenned] = useState<boolean>(false);
  const { showSnackbar, showErrorSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const confirm = useConfirm();

  const deleteMutation = useMutation<void, QueryError>({
    mutationFn: () => deleteActivity(activity.vdl_activite_evenementid),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["Activities"] });
      showSnackbar("Activité supprimée", "success");
    },
    onError: (error: QueryError) => {
      showErrorSnackbar(
        "Erreur dans la création de l'activité.",
        "error",
        error
      );
    },
  });

  async function handleDelete(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.stopPropagation();

    const confirmResult = await confirm({
      title: "Supprimer l'activité?",
      message: "L'activité sera supprimée définitivement.",
      confirmText: "Supprimer",
      cancelText: "Annuler",
    });

    if (!confirmResult) return;

    deleteMutation.mutate();
  }

  return (
    <Accordion
      disableGutters
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      disabled={deleteMutation.isPending}
      sx={{
        backgroundColor: "bg.light",
        boxShadow: "none",
        borderRadius: "4px",
        "&:before": {
          display: "none",
        },
      }}
    >
      {formOpenned ? (
        <ActivityFormDialog
          activityRow={activity}
          activityType={activityType}
          handleCloseForm={() => setFormOpenned(false)}
        />
      ) : null}
      <AccordionSummary expandIcon={<ExpandMore />}>
        <CardBox
          display="flex"
          gap="16px"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          paddingRight="1rem"
        >
          <Typography>
            <Typography
              fontSize={"0.9rem"}
              display="inline"
              component="span"
              fontWeight="600"
            >
              {`${activityDisplayNames._vdl_typedactivite_value_Formatted}: `}
            </Typography>
            <Typography fontSize={"0.9rem"} display="inline" component="span">
              {activity._vdl_typedactivite_value_Formatted}
            </Typography>
          </Typography>
          <Box>
            <Typography>
              <Typography
                fontSize={"0.9rem"}
                display="inline"
                component="span"
                fontWeight="600"
              >
                {`${activityDisplayNames.vdl_debut}: `}
              </Typography>
              <Typography fontSize={"0.9rem"} display="inline" component="span">
                {dateStringToLongWithDayStringUTC(activity.vdl_debut)}
              </Typography>
            </Typography>
            <Typography>
              <Typography
                fontSize={"0.9rem"}
                display="inline"
                component="span"
                fontWeight="600"
              >
                {`${activityDisplayNames.vdl_fin}: `}
              </Typography>
              <Typography fontSize={"0.9rem"} display="inline" component="span">
                {dateStringToLongWithDayStringUTC(activity.vdl_fin)}
              </Typography>
            </Typography>
            <Typography marginLeft="-2px"> {/*Je ne sais pas pourquoi, mais ils n'étaient pas aligné au reste sans ça */}
              <Typography
                fontSize={"0.9rem"}
                display="inline"
                component="span"
                fontWeight="600"
              >
                {`${activityDisplayNames.statuscode}: `}
              </Typography>
              <Typography fontSize={"0.9rem"} display="inline" component="span">
                {getStringFromOptionSet(
                  activity.statuscode,
                  activityOptionSetChoices.statuscode
                )}
              </Typography>
            </Typography>
          </Box>

          <Box
            display="flex"
            gap="16px"
            minWidth={isEditable ? "6rem" : "0px"}
            sx={{
              justifyContent: {
                xs: "start",
                sm: "end",
              },
              gridRow: {
                xs: "span 1",
                sm: "span 2",
                lg: "span 1",
              },
            }}
          >
            {isEditable && (
              <>
                <ClickableIcon
                  backgroundColor="icon.bgLight"
                  icon={<Edit />}
                  handleClick={(e) => {
                    e.stopPropagation();
                    setFormOpenned(true);
                  }}
                  disabled={deleteMutation.isPending}
                />
                <ClickableIcon
                  backgroundColor="icon.bgLight"
                  icon={<Delete />}
                  handleClick={handleDelete}
                  disabled={deleteMutation.isPending}
                />
              </>
            )}
          </Box>
        </CardBox>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: "grid",
          gap: "16px",
          borderRadius: "4px",
        }}
      >
        <Box bgcolor="common.white" padding="1rem" display="grid" rowGap="4px">
          {activityType.champs.map((champ) => {
            if (champ.vdl_nominterne in activity) {
              return (
                <DisplayField
                  key={champ.vdl_nominterne}
                  displayName={champ.vdl_libelleauportail}
                  value={activityFieldToString(activity, champ)}
                  emptyPlaceHolder="---"
                  fontSize="0.9rem"
                />
              );
            }
          })}
          <DisplayField
            displayName={activityDisplayNames.vdl_details}
            value={activity.vdl_details}
            emptyPlaceHolder="---"
            fontSize="0.9rem"
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ActivityCard;
