import {
  CheckBoxInput,
  CommentInput,
  DateInput,
  DropDownChoice,
  DropDownMultipleInput,
  DropDownOptionSetInput,
  FormFields,
  FormSection,
  MultilineInput,
  MultipleOptionSetInput,
  NumberInput,
  OptionSetInput,
  TextInput,
  TimeInput,
} from "../../../../../types";
import { filmingDisplayNames } from "../../../displayNames";
import { incidentOptionSetsChoices } from "../../../optionSetChoices";

export type EditDemandFilmingFields = {
  vdl_titredelaproduction: TextInput;
  vdl_naturedutournage: MultipleOptionSetInput;
  vdl_tournageetudiant: CheckBoxInput;
  vdl_resumeduscenarioenquelquesmots: MultilineInput;
  vdl_nombredevehiculesdeproductionetparticuliers: NumberInput;
  vdl_cubes: NumberInput;
  vdl_roulottes: NumberInput;
  vdl_voituresparticuliers: NumberInput;
  vdl_autresprecisez: TextInput;
  vdl_descriptiondesequipementsaetreinstalles: MultilineInput;

  //Engagements de la maison de production
  vdl_engagementloisetreglementtournage: CheckBoxInput;
  vdl_respectdesconditionsdusoutienmunicipal: CheckBoxInput;
  vdl_envoidesdocuments2semmaximumavantletournage: CheckBoxInput;
  vdl_engagementapayerlafacture: CheckBoxInput;
  vdl_sengageafournirlesjustificatifssurdemande: CheckBoxInput;
};

const editDemandFilmingFields: EditDemandFilmingFields = {
  vdl_titredelaproduction: {
    type: "text",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    label: filmingDisplayNames.vdl_titredelaproduction,
  },
  vdl_naturedutournage: {
    type: "multipleoptions",
    error: "",
    value: [],
    required: true,
    fillLine: true,
    choices: incidentOptionSetsChoices.vdl_naturedutournage,
    label: filmingDisplayNames.vdl_naturedutournage,
  },
  vdl_tournageetudiant: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: filmingDisplayNames.vdl_tournageetudiant,
  },
  vdl_resumeduscenarioenquelquesmots: {
    type: "multiline",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    maxLength: 3000,
    label: filmingDisplayNames.vdl_resumeduscenarioenquelquesmots,
  },
  vdl_nombredevehiculesdeproductionetparticuliers: {
    type: "number",
    error: "",
    value: null,
    required: false,
    fillLine: true,
    label: filmingDisplayNames.vdl_nombredevehiculesdeproductionetparticuliers,
  },
  vdl_cubes: {
    type: "number",
    error: "",
    value: null,
    required: false,
    fillLine: true,
    label: filmingDisplayNames.vdl_cubes,
  },
  vdl_roulottes: {
    type: "number",
    error: "",
    value: null,
    required: false,
    fillLine: true,
    label: filmingDisplayNames.vdl_roulottes,
  },
  vdl_voituresparticuliers: {
    type: "number",
    error: "",
    value: null,
    required: false,
    fillLine: true,
    label: filmingDisplayNames.vdl_voituresparticuliers,
  },
  vdl_autresprecisez: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    label: filmingDisplayNames.vdl_autresprecisez,
  },
  vdl_descriptiondesequipementsaetreinstalles: {
    type: "multiline",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    maxLength: 3000,
    label: filmingDisplayNames.vdl_descriptiondesequipementsaetreinstalles,
  },

  vdl_engagementloisetreglementtournage: {
    type: "checkbox",
    error: "",
    value: false,
    required: true,
    fillLine: true,
    label: filmingDisplayNames.vdl_engagementloisetreglementtournage,
  },
  vdl_respectdesconditionsdusoutienmunicipal: {
    type: "checkbox",
    error: "",
    value: false,
    required: true,
    fillLine: true,
    label: filmingDisplayNames.vdl_respectdesconditionsdusoutienmunicipal,
  },
  vdl_envoidesdocuments2semmaximumavantletournage: {
    type: "checkbox",
    error: "",
    value: false,
    required: true,
    fillLine: true,
    label: filmingDisplayNames.vdl_envoidesdocuments2semmaximumavantletournage,
  },
  vdl_engagementapayerlafacture: {
    type: "checkbox",
    error: "",
    value: false,
    required: true,
    fillLine: true,
    label: filmingDisplayNames.vdl_engagementapayerlafacture,
  },
  vdl_sengageafournirlesjustificatifssurdemande: {
    type: "checkbox",
    error: "",
    value: false,
    required: true,
    fillLine: true,
    label: filmingDisplayNames.vdl_sengageafournirlesjustificatifssurdemande,
  },
};

const editDemandFilimingTournageSection: FormSection<EditDemandFilmingFields> =
  {
    fields: [
      "vdl_titredelaproduction",
      "vdl_naturedutournage",
      "vdl_tournageetudiant",
      "vdl_resumeduscenarioenquelquesmots",
      "vdl_nombredevehiculesdeproductionetparticuliers",
      "vdl_cubes",
      "vdl_roulottes",
      "vdl_voituresparticuliers",

      "vdl_autresprecisez",
      "vdl_descriptiondesequipementsaetreinstalles",
    ],
    title: "Informations sur le tournage",
  };

const editDemandFilimingEngagementSection: FormSection<EditDemandFilmingFields> =
  {
    fields: [
      "vdl_engagementloisetreglementtournage",
      "vdl_respectdesconditionsdusoutienmunicipal",
      "vdl_envoidesdocuments2semmaximumavantletournage",
      "vdl_engagementapayerlafacture",
      "vdl_sengageafournirlesjustificatifssurdemande",
    ],
    title: "Engagements de la maison de production",
  };

const editDemandFilmingSections: FormSection<EditDemandFilmingFields>[] = [
  editDemandFilimingTournageSection,
  editDemandFilimingEngagementSection,
];

export { editDemandFilmingFields, editDemandFilmingSections };
