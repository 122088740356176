import dayjs, { Dayjs } from "dayjs";

export function validateDateString(dateString?: string | null): Dayjs | null {
  try {
    if (!dateString) return null;
    const dayJsDate = dayjs.tz(dateString);
    return dayJsDate.isValid() ? dayJsDate : null;
  } catch (e) {
    return null;
  }
}
