import {
  DropDownLookUpInput,
  FormFields,
  OptionSetInput,
} from "../../../types";
import { activityTypeOptionSetChoices } from "../../ActivityType/optionSetChoices";
import activityDisplayNames from "../displayNames";

export type NewActivityFields = {
  categorie: OptionSetInput;
  _vdl_typedactivite_value: DropDownLookUpInput;
};

const newActivityFields: NewActivityFields = {
  categorie: {
    type: "options",
    error: "",
    value: null,
    required: false,
    choices: activityTypeOptionSetChoices.vdl_categorie,
    fillLine: true,
    label: "Catégorie",
    clearable: true,
    sortBy: "string"
    },
  _vdl_typedactivite_value: {
    type: "lookup",
    error: "",
    value: null,
    choices: [],
    required: true,
    fillLine: true,
    label: activityDisplayNames._vdl_typedactivite_value,
    clearable: true,
    sortBy: "string"
  },
};



export { newActivityFields };
