import { styled } from "@mui/material";
import { Link } from "react-router-dom";

const CustomLinkButton = styled(Link, {
  shouldForwardProp: (prop) => prop !== "secondary" && prop !== "primary",
})<{
  secondary?: boolean;
  primary?: boolean;
}>(({ secondary, primary, theme }) => ({
  textDecoration: "none",
  cursor: "pointer",
  fontFamily: "Roboto, Arial, Helvetica, sans-serif",
  fontWeight: 500,
  color: secondary || primary  ? "#ffffff" : undefined,
  lineHeight: "1em",
  borderRadius: "23px",
  transition: "all 0.35s ease",
  boxSizing: "border-box",
  backgroundColor: secondary ? "#808080" : primary ?  theme.palette.primary.main : undefined,
  textTransform: "none",
  textAlign: "center",
  padding: "10px 30px",
  "&:hover": {
    backgroundColor: secondary ? "#5d5d5d" : primary ? theme.palette.primary.dark : undefined,
    color: secondary || primary  ? "#dddddd" : undefined,
  },
  "&:disabled": {
    backgroundColor: secondary ? "#dcdcdc" : primary ?  "#dcdcdc" : undefined,
  }
}));

export default CustomLinkButton;
