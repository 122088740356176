import { Box } from "@mui/material";
import {
  IncidentParentRow,
} from "../../../../data/Incident/models";
import DemandeCardNew from "../../Demands/DemandCard/DemandeCardNew";
import { ContactRow } from "../../../../data/Contact/models";
import NewDemandButton from "./NewDemandButton";

type Props = {
  demand: IncidentParentRow;
  contacts: ContactRow[];
};

const RegroupmentTab = ({ demand, contacts }: Props) => {

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <NewDemandButton demand={demand} contacts={contacts} />

      {demand.incident_parent_incident.map((d, idx) => (
        <DemandeCardNew key={idx} demand={d} />
      ))}
    </Box>
  );
};

export default RegroupmentTab;
