import React, { useState } from "react";
import {
  IncidentElectronicPanelsPatch,
  IncidentElectronicPanelsRow,
  IncidentPatch,
} from "../../../../data/Incident/models";
import { NewDemandFields } from "../../../../data/Incident/forms/dialog";

import EditDemandLayout from "../EditDemandLayout";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import {
  EditDemandElectronicPanelsFields,
  editDemandElectronicPanelsFields,
  editDemandElectronicPanelsSections,
} from "../../../../data/Incident/forms/electronicPanels";
import useTabs from "../../../../hooks/useTabs";
import { validateDateString } from "../../../../data/common/validateDateString";
import {buildForm} from "../../../form/SimpleForm/buildForm";
import handleSimpleFormSrollToError from "../../../form/SimpleForm/handleSimpleFormSubmitAndScroll";
import { dayjsToUtcString } from "../../../../data/common/dayjsToUtcString";

type Props = {
  demand: IncidentElectronicPanelsRow;
  generalFields: NewDemandFields;
  update: (incident: IncidentPatch) => Promise<void>
};

const ElectronicPanel = ({
  demand,
  generalFields,
  update
}: Props) => {
  const details = demand.vdl_detailsdelademande;

  const [fieldsGeneral, setFieldsGeneral] =
  useState<NewDemandFields>(generalFields);
  
  const [fields, setFields] = useState<EditDemandElectronicPanelsFields>(
    buildForm(editDemandElectronicPanelsFields, {
      vdl_datededebutdelaffichage: validateDateString(
        demand.vdl_detailsdelademande.vdl_datededebutdelaffichage
      ),
      vdl_datedefindelaffichage: validateDateString(
        demand.vdl_detailsdelademande.vdl_datedefindelaffichage
      ),
      vdl_quoi: demand.vdl_detailsdelademande.vdl_quoi ?? "",
      vdl_qui: demand.vdl_detailsdelademande.vdl_qui ?? "",
      vdl_ou: demand.vdl_detailsdelademande.vdl_ou ?? "",
      vdl_telephonepourinformationsurlevenement:
        demand.vdl_detailsdelademande.vdl_telephonepourinformationsurlevenement ?? "",
    })
  );
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      await handleSimpleFormSrollToError(errors[errors.length - 1]);
      return;
    }

    const _demand: IncidentElectronicPanelsPatch = {
      title: fieldsGeneral.title.value,
      _vdl_contactprincipal_value: fieldsGeneral.vdl_contactprincipal.value,
      idtypedeservice: demand.idtypedeservice,
      vdl_detailsdelademande: {
        vdl_datededebutdelaffichage:
          dayjsToUtcString(fields.vdl_datededebutdelaffichage.value),
        vdl_datedefindelaffichage:
          dayjsToUtcString(fields.vdl_datedefindelaffichage.value),
        vdl_quoi: fields.vdl_quoi.value,
        vdl_qui: fields.vdl_qui.value,
        vdl_ou: fields.vdl_ou.value,
        vdl_telephonepourinformationsurlevenement:
          fields.vdl_telephonepourinformationsurlevenement.value,
      },
    };

    await update(_demand)
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_typededemande.vdl_lienhautduformulaireportail}
      parentIncidentId={demand._parentcaseid_value}

    >
      <SimpleFields
        updateRefs
        columns={{ count: 2, minWidth: 280 }}
        sectionsBackground="background.paper"
        fields={fields}
        setFields={setFields}
        sections={editDemandElectronicPanelsSections}
      />
    </EditDemandLayout>
  );
};

export default ElectronicPanel;
