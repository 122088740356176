import { IGeocodeResponse } from "@esri/arcgis-rest-geocoding";
import { ErrorType, OperationType, QueryError } from "../../types";
import fetchMsal from "../fetchMsal";
import { AddressSuggestion } from "./models";

const getAddressSuggestion = async (
  searchText: string
): Promise<AddressSuggestion[]> => {
  try {
    const endpoint = `${process.env.REACT_APP_ESRI_BASE_URL}/suggest?f=json&text=${searchText}&countryCode=CA&category=Subaddress,Point Address`;

    const result = await fetchMsal("GET", endpoint, undefined, true);

    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    }

    const body = await result.json();

    if (!body || !body.suggestions) {
      throw new QueryError(
        ErrorType.INTERNAL,
        "Erreur dans la récupération des adresses"
      );
    }

    return body.suggestions;
  } catch (e) {
    throw QueryError.TransformUnknown(
      e,
      "Suggestions",
      OperationType.RETRIEVEMULTIPLE
    );
  }
};

const getAddressCandidates = async (
    singleLine: string
  ): Promise<IGeocodeResponse["candidates"]> => {
    try {
      const endpoint = `${process.env.REACT_APP_ESRI_BASE_URL}/findAddressCandidates?f=json&singleLine=${singleLine}&countryCode=CA&category=Subaddress,Point Address`;
  
      const result = await fetchMsal("GET", endpoint, undefined, true);
  
      if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");
  
      if (result.status !== 200) {
        throw new QueryError(ErrorType.INTERNAL, await result.text());
      }
  
      const body = await result.json();
  
      if (!body || !body.candidates) {
        throw new QueryError(
          ErrorType.INTERNAL,
          "Erreur dans la récupération des adresses"
        );
      }
  
      return body.candidates;
    } catch (e) {
      throw QueryError.TransformUnknown(
        e,
        "Suggestions",
        OperationType.RETRIEVEMULTIPLE
      );
    }
  };

export { getAddressSuggestion, getAddressCandidates };

