import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { HistoryMessageRow } from "../../../../../../data/History/models";
import { getStringFromOptionSet } from "../../../../../../data/common/fieldToString";
import { historyOptionSetChoices } from "../../../../../../data/History/optionSetChoices";

type Props = {
  activity: HistoryMessageRow;
};

const ActivityCardMessage = ({ activity }: Props) => {
  const [open, setOpen] = useState(false);
  const [displayButton, setDisplayButton] = useState(false);
  const contentRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setOpen(false);
      if (activity.description.includes("\n")) {
        setDisplayButton(true);
        return;
      }
      const contentElement = contentRef.current;
      if (contentElement) {
        const isOverflowing =
          contentElement.scrollWidth > contentElement.clientWidth;
        setDisplayButton(isOverflowing);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box maxWidth="100%">
            <Typography color="text.secondary">
        <Typography color="text.secondary" component="span">
        {activity.vdl_viaportail
          ? "Vous avez envoyé un message de type"
          : "Vous avez reçu un message de type"}
        </Typography>
        <Typography color="text.secondary" component="span" fontWeight="bold">
          {" " + getStringFromOptionSet(activity.vdl_typedinformation, historyOptionSetChoices.vdl_typedinformation)}
        </Typography>
      </Typography>
      <Box
        bgcolor="background.default"
        width="100%"
        padding="8px"
        overflow="visible"
        maxWidth="100%"
      >
        <Typography fontWeight="bold" color="text.secondary" fontSize="1.1rem" mb="4px">
          {activity.subject}
        </Typography>
        <Typography
          height={open ? undefined : "1.1rem"}
          overflow={open ? undefined : "hidden"}
          textOverflow={open ? undefined : "ellipsis"}
          whiteSpace={open ? "pre-line" : "nowrap"}
          lineHeight="1.1"
          color="text.secondary"
          ref={contentRef}
        >
          {activity.description}
        </Typography>
        {displayButton ? (
          <Button
            color="primary"
            sx={{
              minHeight: 0,
              minWidth: 0,
              padding: 0,
              textTransform: "none",
            }}
            onClick={() => setOpen(!open)}
          >
            {open ? "Afficher moins" : "Afficher plus..."}
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};

export default ActivityCardMessage;
