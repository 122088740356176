import React, { useEffect, useState } from "react";
import { ErrorType, QueryError, TabContent } from "../types";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import BasicTabs from "../components/layouts/AppTabs/AppTabs";
import GeneralTab from "../components/pageSpecific/Demand/GeneralTab/GeneralTab";
import { Link, useNavigate, useParams } from "react-router-dom";
import useUser from "../hooks/useUserData";
import { AccountRow, AccountRowNoContacts } from "../data/Account/models";
import { Box, Grid, Typography } from "@mui/material";
import SpecificTab from "../components/pageSpecific/Demand/SpecificTab/SpecificTab";
import CustomLinkButton from "../components/common/buttons/CustomLinkButton";
import { RouterRoutes, routes } from "../routes/routes";
import RegroupmentTab from "../components/pageSpecific/Demand/RegroupmentTab/RegroupmentTab";
import useTabs from "../hooks/useTabs";
import CustomButton from "../components/common/buttons/CustomButton";
import { useSnackbar } from "../hooks/useSnackbar";
import { useConfirm } from "../hooks/useConfirmation";
import AppTabsSkeleton from "../components/layouts/AppTabs/AppTabsSkeleton";
import AccountCardSkeleton from "../components/pageSpecific/Demand/GeneralTab/AccountCard/AccountCardSkeleton";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";
import AttachmentCardSkeleton from "../components/pageSpecific/Demand/GeneralTab/AttachmentCard/AttachmentCardSkeleton";
import { IncidentRow } from "../data/Incident/models";
import { deleteIncident, getIncident, updateStatusIncident } from "../data/Incident/queries";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import QueryErrorMessage from "../components/common/error/QueryErrorMessage";
import {
  canUserDeleteIncident,
  canUserEditIncident,
  isIncidentDeletable,
  isIncidentEditable,
  isIncidentOfTypeEvent,
  isSubIncident,
} from "../data/Incident/rules";
import { getAccount } from "../data/Account/queries";
import ActivitiesTab from "../components/pageSpecific/Demand/ActivitiesTab/ActivitiesTab";

const Demand = () => {
  const { id } = useParams();
  const { userData } = useUser();
  const queryClient = useQueryClient();
  const { activeTab } = useTabs();
  const { showSnackbar, showErrorSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const confirm = useConfirm();

  const demandQuery = useQuery<IncidentRow, QueryError>({
    queryKey: ["Demand", id],
    queryFn: () => getIncident(id),
  });
  const accountQuery = useQuery<AccountRow, QueryError>({
    queryKey: ["Account"],
    queryFn: () => getAccount(),
  });


  const updateMutation = useMutation<void, QueryError, { incidentid: string }>({
    mutationFn: ({ incidentid }) =>
      updateStatusIncident(incidentid, {
        statuscode: 948300002,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["Demand", id] });
      queryClient.invalidateQueries({ queryKey: ["Demands"] });
      showSnackbar("Demande soumise", "success");
    },
    onError: async (error: QueryError) => {
      if(error.type === ErrorType.UNPROCESSABLE) {
        await confirm({
          title: "Impossible de soumettre cette demande",
          message: error.message,
          confirmText: "Ok",
        })
        return;
      }
      showErrorSnackbar(
        error.type === ErrorType.FORBIDDEN ||
          error.type === ErrorType.UNAUTHORIZED
          ? "Vous n'êtes pas autorisé à modifier cette demande."
          : "Erreur dans la modification de la demande.",
        "error",
        error
      );
    },
  });

  const deleteMutation = useMutation<void, QueryError, { incidentid: string }>({
    mutationFn: ({ incidentid }) =>
      deleteIncident(incidentid),
    onSuccess: () => {
      navigate("/");
      queryClient.invalidateQueries({ queryKey: ["Demands"] });
      showSnackbar("Demande supprimée", "success");
    },
    onError: (error: QueryError) => {
      showErrorSnackbar(
        error.type === ErrorType.FORBIDDEN ||
          error.type === ErrorType.UNAUTHORIZED
          ? "Vous n'êtes pas autorisé à supprimer cette demande."
          : "Erreur dans la suppression de la demande.",
        "error",
        error
      );
    },
  });

  const isLoading =
    demandQuery.isFetching ||
    accountQuery.isLoading ||
    updateMutation.isPending 
    || deleteMutation.isPending;
  const error = demandQuery.error ?? accountQuery.error;

  if (isLoading) return <DemandPageLoading activeTab={activeTab} />;

  if (error || !demandQuery.data || !accountQuery.data)
    return <QueryErrorMessage error={error} />;

  const getTabs = (): TabContent[] => {
    const tabData: TabContent[] = [
      {
        label: "Informations générales",
        component: (
          <GeneralTab demand={demandQuery.data} account={accountQuery.data} />
        ),
      },
      {
        label: "Informations spécifiques",
        component: <SpecificTab demand={demandQuery.data} />,
      },
    ];
    if (isIncidentOfTypeEvent(demandQuery.data)) {
      tabData.push({
        label: `Activités`,
        component: <ActivitiesTab demand={demandQuery.data} />,
      });
      tabData.push({
        label: `Demandes associées (${demandQuery.data.incident_parent_incident.length})`,
        component: (
          <RegroupmentTab
            demand={demandQuery.data}
            contacts={accountQuery.data.contacts}
          />
        ),
      });
    }
    return tabData;
  };

  const handleSubmit = async () => {
    if (demandQuery.data.incident_parent_incident.length > 0) {
      const message = `-${demandQuery.data.incident_parent_incident
        .map((demand) => {
          return demand.title;
        })
        .join(",\n-")} `;

      const confirmResult = await confirm({
        title: `${
          demandQuery.data.incident_parent_incident.length === 1
            ? `Une sous-demande sera soumise.`
            : `${demandQuery.data.incident_parent_incident.length} sous-demandes seront soumises.`
        }`,
        message: `${message} \n`,
        confirmText: "Continuer",
        cancelText: "Annuler",
      });

      if (!confirmResult) {
        return;
      }
    }

    try {
      let errorFields: string[] = [];
      /*if (1 < 0 && demand.type === "Télévision communautaire") {
        const details = demand.vdl_detailsdelademande;

        Object.keys(details).forEach((_key) => {
          const key = _key as keyof typeof details;
          if (
            Object.prototype.hasOwnProperty.call(
              editDemandTelevisionDisplayFields,
              key
            )
          ) {
            if (
              editDemandTelevisionDisplayFields[
                key as keyof typeof editDemandTelevisionDisplayFields
              ].required ??
              false
            ) {
              switch (typeof details[key]) {
                case "undefined":
                  errorFields.push(televisionDisplayDisplayNames[key]);
                  break;
                case "number":
                  break;
                default:
                  break;
              }
            }
          }
        }); */
      if (errorFields.length > 0) {
        await confirm({
          title: "Cette demande n'est pas complète",
          message: `Certains champs requis sont vides, veuillez les remplir avant de soumettre la demande \n${errorFields.join(
            "\n"
          )}`,
          confirmText: "Ok",
        });
        return;
      }
    } catch (error) {}

    updateMutation.mutate({
      incidentid: demandQuery.data.incidentid,
    });
  };

  const handleDelete = async () => {
    const confirmResult = await confirm({
      title: "Supprimer la demande?",
      message: "Une fois supprimée, la demande ne pourra pas être récupérée. Si elle contient des sous-demandes, elles seront aussi supprimées",
      confirmText: "Supprimer",
      cancelText: "Annuler",
    });
    if (!confirmResult) return;

    deleteMutation.mutate({
      incidentid: demandQuery.data.incidentid,
    });
  };
  return (
    <PageTitleLayout
      title={demandQuery.data.ticketnumber}
      sideTitleComponent={
        isIncidentEditable(demandQuery.data) &&
        canUserEditIncident(
          userData,
          demandQuery.data.vdl_contactprincipal?.contactid
        ) ? (
          <Box display="flex" gap="8px" flexWrap="wrap">
            {activeTab < 2 ? (
              <CustomLinkButton
                to={`${RouterRoutes.Edit}?tab=${activeTab}`}
                secondary
              >
                Modifier
              </CustomLinkButton>
            ) : null}
            {activeTab < 2 &&
              canUserDeleteIncident(
                userData,
                demandQuery.data.vdl_contactprincipal?.contactid
              ) &&
              isIncidentDeletable(demandQuery.data) && (
                <CustomButton onClick={handleDelete} secondary>
                  Supprimer
                </CustomButton>
              )}
            {!isSubIncident(demandQuery.data) ? (
              <CustomButton onClick={handleSubmit} secondary>
                Soumettre
              </CustomButton>
            ) : null}
          </Box>
        ) : undefined
      }
      underTitleComponent={
        demandQuery.data._parentcaseid_value ? (
          <Typography
            color="white"
            fontWeight="bold"
            component={Link}
            to={`${routes.demands.path}/${demandQuery.data._parentcaseid_value}/?tab=0`}
          >
            Demande parent
          </Typography>
        ) : undefined
      }
    >
        <BasicTabs tabs={getTabs()} />
    </PageTitleLayout>
  );
};

export default Demand;

const DemandPageLoading = ({ activeTab }: { activeTab?: number }) => {
  return (
    <PageTitleLayout title="Modifier demande" isLoading>
      <AppTabsSkeleton tabsNumber={2} activeTabIndex={activeTab??0} />
      <BoxResponsivePadding gap="16px">
        <Grid container columnGap={"1rem"} rowGap={"1rem"}>
          <Grid item xs={12} height={"100%"} xl>
            <AccountCardSkeleton />
          </Grid>
          <Grid item xs={12} xl={4.5}>
            <AccountCardSkeleton />
            <Grid style={{ marginTop: "1rem" }}>
              <AttachmentCardSkeleton />
            </Grid>
          </Grid>
        </Grid>
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};
