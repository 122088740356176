import React, { useState } from "react";
import EditDemandLayout from "../EditDemandLayout";
import {
  IncidentActivitiesCalendarRow,
  IncidentActivitiesCalendarPatch,
  IncidentPatch,
} from "../../../../data/Incident/models";
import { NewDemandFields } from "../../../../data/Incident/forms/dialog";

import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import {
  EditDemandActivitiesCalendar,
  editDemandActivitiesCalendarFields,
  editDemandActivitiesCalendarSections,
} from "../../../../data/Incident/forms/activitesCalendar";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import { validateDateString } from "../../../../data/common/validateDateString";
import convertIsoNumberToNumber from "../../../../data/common/isoStringToNumber";
import { fileToBase64 } from "../../../../data/common/fileToBase64";
import { dynamicsFileToFileType } from "../../../../data/common/buildFile";
import { buildForm } from "../../../form/SimpleForm/buildForm";
import timeout from "../../../../utils/timeout";
import handleSimpleFormSrollToError from "../../../form/SimpleForm/handleSimpleFormSubmitAndScroll";
import { dayjsToUtcString } from "../../../../data/common/dayjsToUtcString";

type Props = {
  demand: IncidentActivitiesCalendarRow;
  generalFields: NewDemandFields;
  update: (incident: IncidentPatch) => Promise<void>;
};

const ActivitiesCalendar = ({ demand, generalFields, update }: Props) => {

  const [fieldsGeneral, setFieldsGeneral] =
    useState<NewDemandFields>(generalFields);

  const [fields, setFields] = useState<EditDemandActivitiesCalendar>(
    buildForm(editDemandActivitiesCalendarFields, {
      vdl_titredelevenement: demand.vdl_detailsdelademande.vdl_titredelevenement ?? "",
      vdl_lieudelevenement: demand.vdl_detailsdelademande.vdl_lieudelevenement ?? "",
      vdl_adressecompletedulieudelevenement:
        demand.vdl_detailsdelademande.vdl_adressecompletedulieudelevenement ?? "",
      vdl_piecejointe: dynamicsFileToFileType(demand.vdl_detailsdelademande.vdl_piecejointe),
      vdl_dateetheurededebut: validateDateString(
        demand.vdl_detailsdelademande.vdl_dateetheurededebut
      ),
      vdl_dateetheuredefin: validateDateString(demand.vdl_detailsdelademande.vdl_dateetheuredefin),
      vdl_commentairessurleshorairesdelevenement:
        demand.vdl_detailsdelademande.vdl_commentairessurleshorairesdelevenement ?? "",
      vdl_cout: demand.vdl_detailsdelademande.vdl_cout ?? null,
      vdl_adultes: demand.vdl_detailsdelademande.vdl_adultes ? demand.vdl_detailsdelademande.vdl_adultes.toString() : "",
      vdl_retraites: demand.vdl_detailsdelademande.vdl_retraites
        ? demand.vdl_detailsdelademande.vdl_retraites.toString()
        : "",
      vdl_etudiants: demand.vdl_detailsdelademande.vdl_etudiants
        ? demand.vdl_detailsdelademande.vdl_etudiants.toString()
        : "",
      vdl_enfants: demand.vdl_detailsdelademande.vdl_enfants ? demand.vdl_detailsdelademande.vdl_enfants.toString() : "",
      vdl_prixdefamille: demand.vdl_detailsdelademande.vdl_prixdefamille
        ? demand.vdl_detailsdelademande.vdl_prixdefamille.toString()
        : "",
      vdl_prixdegroupe: demand.vdl_detailsdelademande.vdl_prixdegroupe
        ? demand.vdl_detailsdelademande.vdl_prixdegroupe.toString()
        : "",
      vdl_autressvpdetaillez2: demand.vdl_detailsdelademande.vdl_autressvpdetaillez2 ?? "",
      vdl_stationnementpayant: demand.vdl_detailsdelademande.vdl_stationnementpayant ?? false,
      vdl_coutdestationnement: demand.vdl_detailsdelademande.vdl_coutdestationnement
        ? demand.vdl_detailsdelademande.vdl_coutdestationnement.toString()
        : "",
      vdl_clientelesvisees: demand.vdl_detailsdelademande.vdl_clientelesvisees ?? [],
      vdl_siteinternet: demand.vdl_detailsdelademande.vdl_siteinternet ?? "",
      vdl_pagefacebook: demand.vdl_detailsdelademande.vdl_pagefacebook ?? "",
      vdl_courriel: demand.vdl_detailsdelademande.vdl_courriel ?? "",
      vdl_telephonecalendrier: demand.vdl_detailsdelademande.vdl_telephonecalendrier ?? "",
      vdl_descriptionsommairedelevenement:
        demand.vdl_detailsdelademande.vdl_descriptionsommairedelevenement ?? "",
      vdl_autreinformationpertinente:
        demand.vdl_detailsdelademande.vdl_autreinformationpertinente ?? "",
    })
  );
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    try {
      const errors = handleSimpleFormSubmit(fields, setFields);

      if (errors.length > 0) {
        if (activeTab !== 1) changeTab(1);
        await handleSimpleFormSrollToError(errors[errors.length - 1]);
        return;
      }

      const _demand: IncidentActivitiesCalendarPatch = {
        title: fieldsGeneral.title.value,
        _vdl_contactprincipal_value: fieldsGeneral.vdl_contactprincipal.value,
        idtypedeservice: demand.idtypedeservice,
        vdl_detailsdelademande: {
          vdl_titredelevenement: fields.vdl_titredelevenement.value,
          vdl_lieudelevenement: fields.vdl_lieudelevenement.value,
          vdl_adressecompletedulieudelevenement:
            fields.vdl_adressecompletedulieudelevenement.value,
          vdl_dateetheurededebut:
            dayjsToUtcString(fields.vdl_dateetheurededebut.value),
          vdl_dateetheuredefin:
            dayjsToUtcString(fields.vdl_dateetheuredefin.value),
          vdl_commentairessurleshorairesdelevenement:
            fields.vdl_commentairessurleshorairesdelevenement.value,
          vdl_cout: fields.vdl_cout.value ?? undefined,
          vdl_adultes: convertIsoNumberToNumber(fields.vdl_adultes.value),
          vdl_retraites: convertIsoNumberToNumber(fields.vdl_retraites.value),
          vdl_etudiants: convertIsoNumberToNumber(fields.vdl_etudiants.value),
          vdl_enfants: convertIsoNumberToNumber(fields.vdl_enfants.value),
          vdl_prixdefamille: convertIsoNumberToNumber(
            fields.vdl_prixdefamille.value
          ),
          vdl_prixdegroupe: convertIsoNumberToNumber(
            fields.vdl_prixdegroupe.value
          ),
          vdl_autressvpdetaillez2: fields.vdl_autressvpdetaillez2.value,
          vdl_stationnementpayant: fields.vdl_stationnementpayant.value,
          vdl_coutdestationnement: convertIsoNumberToNumber(
            fields.vdl_coutdestationnement.value
          ),
          vdl_siteinternet: fields.vdl_siteinternet.value,
          vdl_pagefacebook: fields.vdl_pagefacebook.value,
          vdl_courriel: fields.vdl_courriel.value,
          vdl_telephonecalendrier: fields.vdl_telephonecalendrier.value,
          vdl_descriptionsommairedelevenement:
            fields.vdl_descriptionsommairedelevenement.value,
          vdl_autreinformationpertinente:
            fields.vdl_autreinformationpertinente.value,
          vdl_clientelesvisees: fields.vdl_clientelesvisees.value,
        },
      };

      if (fields.vdl_piecejointe.changed) {
        if (fields.vdl_piecejointe.value) {
          const pieceJointeToBase = await fileToBase64(
            fields.vdl_piecejointe.value
          );

          if (pieceJointeToBase) {
            _demand.vdl_detailsdelademande.vdl_piecejointe = {
              filename: fields.vdl_piecejointe.value.name,
              content: pieceJointeToBase,
            };
          }
        }
      }

      await update(_demand);
    } catch (e) {}
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_typededemande.vdl_lienhautduformulaireportail}
      parentIncidentId={demand._parentcaseid_value}

    >
      <SimpleFields
        updateRefs
        fields={fields}
        setFields={setFields}
        sections={editDemandActivitiesCalendarSections}
        sectionsBackground="background.paper"
      />
    </EditDemandLayout>
  );
};

export default ActivitiesCalendar;
