import { Dialog } from "@mui/material";
import React, { useState } from "react";
import handleSimpleFormSubmit from "../../../../../../form/SimpleForm/handleSimpleFormSubmit";
import {
  NewHistoryFields,
  newActivityFields,
} from "../../../../../../../data/History/forms/new";
import useUser from "../../../../../../../hooks/useUserData";
import { useSnackbar } from "../../../../../../../hooks/useSnackbar";
import CustomDialogHeader from "../../../../../../dialog/CustomDialogHeader/CustomDialogHeader";
import clone from "just-clone";
import { fileToBase64 } from "../../../../../../../data/common/fileToBase64";
import { Base64FilePost } from "../../../../../../../data/common/types";
import SimpleFormWithSectionsWithState from "../../../../../../form/SimpleForm/SimpleFormWithSectionsWithState";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HistoryPost } from "../../../../../../../data/History/models";
import { createIncidentMessage } from "../../../../../../../data/History/queries";
import { buildPartialFormObj } from "../../../../../../form/SimpleForm/buildForm";
import { QueryError } from "../../../../../../../types";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  demandid: string;
};

const NewMessageDialog = ({
  open,
  setOpen,
  demandid,
}: Props) => {
  const [fields, setFields] = useState<NewHistoryFields>(
    buildPartialFormObj(newActivityFields, {})
  );
  const [isFormLoading, setIsFormLoading] = useState(false);
  const { userData } = useUser();
  const queryClient = useQueryClient();
  const { showSnackbar, showErrorSnackbar } = useSnackbar();



  const mutation = useMutation<string | undefined, QueryError, HistoryPost>({
    mutationFn: (historyPost : HistoryPost) => createIncidentMessage(demandid, historyPost),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["Demand", demandid, "History"] });
      queryClient.invalidateQueries({ queryKey: ["Demand", demandid, "Attachments"] });
      setOpen(false);
    },
    onError: (e) => {
      showErrorSnackbar("Erreur dans la création du message", "error", e)
    },
  });
  
  async function handleSubmit() {
    try {
      setIsFormLoading(true);
      const errors = handleSimpleFormSubmit(fields, setFields);

      if (errors.length < 1 && fields.vdl_typedinformation.value) {
        const tempFiles: Base64FilePost[] = [];
        fields.files.value.forEach(async (file) => {
          const pieceJointeToBase = await fileToBase64(file);
          if (pieceJointeToBase) {
            tempFiles.push({
              filename: file.name,
              content: pieceJointeToBase,
            });
          }
        });
        
        const activityResponse : HistoryPost = {
          subject: fields.subject.value,
          description: fields.description.value,
          vdl_typedinformation: fields.vdl_typedinformation.value,
          files: tempFiles,
        }

        mutation.mutate(activityResponse);
      }
    } catch (e) {
      let message = "Erreur lors du traitement dse données ";
      showSnackbar(message, "error");
    } finally {
      setIsFormLoading(false);
    }
  }
  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <CustomDialogHeader title="Ajouter une information" setOpen={setOpen} />
        <SimpleFormWithSectionsWithState
          fields={fields}
          setFields={setFields}
          handleSuccess={handleSubmit}
          isFormLoading={isFormLoading || mutation.isPending}
          submitText="Envoyer"
        />
    </Dialog>
  );
};

export default NewMessageDialog;
