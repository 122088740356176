import { Box, Dialog, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../../common/buttons/CustomButton";
import ReservationsDialog from "./ReservationsDialog/ReservationsDialog";
import { SiteRow } from "../../../../../data/Plateau/models.ts";
import {
  IncidentPublicSpaceReservationRowBase,
  IncidentPublicSpaceReservationEditing,
  IncidentPublicSpaceReservationRowNew,
} from "../../../../../data/Incident/types/publicSpace/reservation";
import ReservationCard from "./ReservationCard";
import { useQuery } from "@tanstack/react-query";
import { QueryError } from "../../../../../types";
import { getSites } from "../../../../../data/Plateau/queries";

type Props = {
  reservations: IncidentPublicSpaceReservationEditing[];
  setDemandReservations: React.Dispatch<
    React.SetStateAction<IncidentPublicSpaceReservationEditing[]>
  >;
};

const ReservationsSection = ({
  reservations,
  setDemandReservations,
}: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const { data, error, isLoading, isFetching } = useQuery<
    SiteRow[],
    QueryError
  >({
    queryKey: ["Sites"],
    queryFn: () => getSites(),
    staleTime: 600000,
    gcTime: 600000,
  });

  function addReservation(
    newReservation: IncidentPublicSpaceReservationRowNew
  ) {
    setDemandReservations((prev) => [...prev, newReservation]);
    setDialogOpen(false);
  }

  function deleteReservation(idx: number) {
    setDemandReservations((prev) => {
      if ("vdl_reservationid" in prev[idx]) {
        return prev.map((item, index) =>
          index === idx ? { ...item, delete: true } : item
        );
      }
      return prev.filter((_, index) => {
        return index !== idx;
      });
    });
  }
  return (
    <>
      {dialogOpen ? (
        <ReservationsDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          addReservation={addReservation}
        />
      ) : null}
      <Box>
        <Box display="flex" justifyContent="space-between" paddingBottom="16px">
          <Typography variant="h5" color="primary" paddingBottom="4px">
            Réservations
          </Typography>
          <CustomButton primary onClick={() => setDialogOpen(true)}>
            Ajouter
          </CustomButton>
        </Box>
        <Box display="grid" gap="16px">
          {reservations.map((reservation, idx) => {
            if (!("delete" in reservation)) {
              let key: string;
              if ("vdl_reservationid" in reservation) {
                key =
                  reservation.vdl_reservationid +
                  reservation._vdl_sousplateau_value;
              } else {
                key =
                  reservation._vdl_sousplateau_value +
                  reservation.vdl_debutdelareservation +
                  reservation.vdl_findelareservation;
              }
              return (
                <ReservationCard
                  key={key}
                  reservation={reservation}
                  deleteReservation={() => deleteReservation(idx)}
                />
              );
            }
          })}
        </Box>
      </Box>
    </>
  );
};

export default ReservationsSection;
