import { EntityOptionSetChoices } from "../../types";
type HistoryOptionSetChoices = EntityOptionSetChoices<"vdl_typedinformation">;

export const historyOptionSetChoices : HistoryOptionSetChoices = {
    vdl_typedinformation : {
        948300000: "Information générale",
        948300001: "Modification de demande",
        948300002: "Information supplémentaire nécessaire",
        948300003: "Annulation de la demande",
        948300004: "Autres",
    },

}