import { FormLabel, Typography } from "@mui/material";
import {
  DropDownChoice,
  DropDownOptionSetInput,
  MultilineInput,
  MultipleFilesInput,
  OptionSetInput,
  TextInput,
} from "../../../types";
import historyDisplayNames from "../displayNames";
import { historyOptionSetChoices } from "../optionSetChoices";

export type NewHistoryFields = {
  vdl_typedinformation: OptionSetInput;
  subject: TextInput;
  description: MultilineInput;
  files: MultipleFilesInput;
};

const vdl_typedinformationChoices: DropDownChoice[] = [
  {
    value: "Information générale",
  },
  {
    value: "Modification de demande",
  },
  {
    value: "Information supplémentaire nécessaire",
  },
  {
    value: "Annulation de la demande",
  },
  {
    value: "Autres",
  },
];

const AttachmentLabel = ({ label }: { label: string }) => {
  return (
    <Typography component="label" variant="h5" color="primary">
      {label}
    </Typography>
  );
};

const newHistoryFields: NewHistoryFields = {
  vdl_typedinformation: {
    type: "options",
    error: "",
    value: null,
    choices: historyOptionSetChoices.vdl_typedinformation,
    required: true,
    fillLine: true,
    label: historyDisplayNames.vdl_typedinformation,
  },
  subject: {
    type: "text",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    label: historyDisplayNames.subject,
  },
  description: {
    type: "multiline",
    error: "",
    value: "",
    required: true,
    rows: {
      min: 4,
      max: 10,
    },
    maxLength: 2000,
    helperText: "2 000 caractères maximum",
    fillLine: true,
    label: historyDisplayNames.description,
  },
  files: {
    type: "multiplefiles",
    error: "",
    value: [],
    required: false,
    maxLength: 5,
    fillLine: true,
    helperText:
      "Votre fichier doit être en format GIF, JPEG, JPG, PDF ou PNG et doit avoir une taille maximale de 100 Mo",
    accept: [".gif", ".png", ".jpg", ".PNG", ".jpeg", ".pdf"],
    labelComponent: AttachmentLabel,
    label: "Pièces jointes",
  },
};

export { newHistoryFields as newActivityFields };
