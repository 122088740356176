import dayjs, { Dayjs } from "dayjs";

export function dayjsToUtcString(date?: Dayjs | null): string | null {
  try {
    if (date === null || date === undefined) return null;

    return date.utc(true).toISOString();
  } catch (e) {
    return null;
  }
}
