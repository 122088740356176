import React, { useState } from "react";
import {
  IncidentAdmissibilityPatch,
  IncidentAdmissibilityRow,
  IncidentPatch,
} from "../../../../data/Incident/models";
import { NewDemandFields } from "../../../../data/Incident/forms/dialog";

import EditDemandLayout from "../EditDemandLayout";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import {
  EditDemandAdmissibilityFields,
  editDemandAdmissibilityFields,
  editDemandAdmissiblityRenouvellementSections,
  editDemandAdmissiblitySections,
} from "../../../../data/Incident/forms/admissibility";
import { validateDateString } from "../../../../data/common/validateDateString";
import {
  buildForm,
  buildPartialFormObj,
} from "../../../form/SimpleForm/buildForm";
import { fileToBase64 } from "../../../../data/common/fileToBase64";
import { dynamicsFileToFileType } from "../../../../data/common/buildFile";
import handleSimpleFormSrollToError from "../../../form/SimpleForm/handleSimpleFormSubmitAndScroll";
import { dayjsToUtcString } from "../../../../data/common/dayjsToUtcString";

type Props = {
  demand: IncidentAdmissibilityRow;
  generalFields: NewDemandFields;
  update: (incident: IncidentPatch) => Promise<void>;
};

const Admissibility = ({ demand, generalFields, update }: Props) => {
  const [fieldsGeneral, setFieldsGeneral] =
    useState<NewDemandFields>(generalFields);

  const [fields, setFields] = useState<EditDemandAdmissibilityFields>(
    buildPartialFormObj(editDemandAdmissibilityFields, {
      vdl_dorganismeabutnonlucratif: {
        value:
          demand.vdl_detailsdelademande.vdl_dorganismeabutnonlucratif ?? false,
      },
      vdl_dorganismedebienfaisanceenregistre: {
        value:
          demand.vdl_detailsdelademande
            .vdl_dorganismedebienfaisanceenregistre ?? false,
      },
      vdl_etesvousunchapitrelavalloisduneinstancepro: {
        value:
          demand.vdl_detailsdelademande
            .vdl_etesvousunchapitrelavalloisduneinstancepro ?? false,
      },
      vdl_siouiquelestlenometleneqdelorg: {
        value:
          demand.vdl_detailsdelademande.vdl_siouiquelestlenometleneqdelorg ??
          "",
      },
      vdl_datedeladeclarationaureq: {
        value: validateDateString(
          demand.vdl_detailsdelademande.vdl_datedeladeclarationaureq
        ),
      },
      vdl_aucuneassurance: {
        value: demand.vdl_detailsdelademande.vdl_aucuneassurance ?? false,
      },
      vdl_assurancedesadministrateurs: {
        value:
          demand.vdl_detailsdelademande.vdl_assurancedesadministrateurs ??
          false,
      },
      vdl_assurancedesbiens: {
        value: demand.vdl_detailsdelademande.vdl_assurancedesbiens ?? false,
      },
      vdl_assuranceresponsabilitecivile: {
        value:
          demand.vdl_detailsdelademande.vdl_assuranceresponsabilitecivile ??
          false,
      },
      vdl_assuranceadministrateursetdirigeants: {
        value:
          demand.vdl_detailsdelademande
            .vdl_assuranceadministrateursetdirigeants ?? false,
      },
      vdl_nomdelacompagniedassurance: {
        value:
          demand.vdl_detailsdelademande.vdl_nomdelacompagniedassurance ?? "",
      },
      vdl_numerodelapolicedassurance: {
        value:
          demand.vdl_detailsdelademande.vdl_numerodelapolicedassurance ?? "",
      },
      vdl_datedecheancedelapolicedassurance: {
        value: validateDateString(
          demand.vdl_detailsdelademande.vdl_datedecheancedelapolicedassurance
        ),
      },
      vdl_missionetobjectif: {
        value: demand.vdl_detailsdelademande.vdl_missionetobjectif ?? "",
      },
      vdl_realitebesoinsoutien: {
        value: demand.vdl_detailsdelademande.vdl_realitebesoinsoutien ?? "",
      },
      vdl_pointsdeservicealaval: {
        value: demand.vdl_detailsdelademande.vdl_pointsdeservicealaval ?? false,
      },
      vdl_siouiaquelendroit: {
        value: demand.vdl_detailsdelademande.vdl_siouiaquelendroit ?? "",
      },
      vdl_dansqueldomainedactivitevotreorganismeinter: {
        value:
          demand.vdl_detailsdelademande
            .vdl_dansqueldomainedactivitevotreorganismeinter,
      },
      vdl_siautresprecisez: {
        value: demand.vdl_detailsdelademande.vdl_siautresprecisez ?? "",
      },
      vdl_principauxserviceouactivites: {
        value:
          demand.vdl_detailsdelademande.vdl_principauxserviceouactivites ?? "",
      },

      vdl_desregroupementsetoudesorganismes: {
        value:
          demand.vdl_detailsdelademande.vdl_desregroupementsetoudesorganismes ??
          "",
      },
      vdl_territoiresdesservisparlorganisme2: {
        value:
          demand.vdl_detailsdelademande
            .vdl_territoiresdesservisparlorganisme2 ?? [],
      },
      vdl_siailleursprecisez: {
        value: demand.vdl_detailsdelademande.vdl_siailleursprecisez ?? "",
      },
      vdl_nombredeparticipantsauxactivites: {
        value:
          demand.vdl_detailsdelademande.vdl_nombredeparticipantsauxactivites ??
          "",
      },
      vdl_typedeclientele: {
        value: demand.vdl_detailsdelademande.vdl_typedeclientele ?? null,
      },
      vdl_prescolaire0a4ans: {
        value: demand.vdl_detailsdelademande.vdl_prescolaire0a4ans ?? "",
      },
      vdl_enfants5a12ans: {
        value: demand.vdl_detailsdelademande.vdl_enfants5a12ans ?? "",
      },
      vdl_adolescents13a17ans: {
        value: demand.vdl_detailsdelademande.vdl_adolescents13a17ans ?? "",
      },
      vdl_adultes18a64ans: {
        value: demand.vdl_detailsdelademande.vdl_adultes18a64ans ?? "",
      },
      vdl_aines65ansetplus65: {
        value: demand.vdl_detailsdelademande.vdl_aines65ansetplus65 ?? "",
      },
      vdl_politiquedeverification: {
        value:
          demand.vdl_detailsdelademande.vdl_politiquedeverification ?? false,
      },
      vdl_entente: {
        value: demand.vdl_detailsdelademande.vdl_entente ?? false,
      },
      vdl_codedeconduite: {
        value: demand.vdl_detailsdelademande.vdl_codedeconduite ?? false,
      },
      vdl_formationsurladoptiondecomportementsadequats: {
        value:
          demand.vdl_detailsdelademande
            .vdl_formationsurladoptiondecomportementsadequats ?? false,
      },
      vdl_codedeconduitedelorganisation: {
        value:
          demand.vdl_detailsdelademande.vdl_codedeconduitedelorganisation ??
          false,
      },
      vdl_codeetsignatureduncontratethique: {
        value:
          demand.vdl_detailsdelademande.vdl_codeetsignatureduncontratethique ??
          false,
      },
      vdl_verificationantecedentjudiciaire: {
        value:
          demand.vdl_detailsdelademande.vdl_verificationantecedentjudiciaire ??
          false,
      },
      vdl_mecanismedegestionderisqueencasdedoute: {
        value:
          demand.vdl_detailsdelademande
            .vdl_mecanismedegestionderisqueencasdedoute ?? false,
      },
      vdl_utilisationdelaplateformejebenevoleca: {
        value:
          demand.vdl_detailsdelademande
            .vdl_utilisationdelaplateformejebenevoleca ?? false,
      },
      vdl_jeveuxplusdinformationacesujet: {
        value:
          demand.vdl_detailsdelademande.vdl_jeveuxplusdinformationacesujet ??
          false,
      },
      vdl_nombrederencontresduca: {
        value: demand.vdl_detailsdelademande.vdl_nombrederencontresduca ?? "",
      },
      vdl_nombredepresencesalaga: {
        value: demand.vdl_detailsdelademande.vdl_nombredepresencesalaga ?? "",
      },
      vdl_datedeladerniereassembleegeneraleannuelle: {
        value: validateDateString(
          demand.vdl_detailsdelademande
            .vdl_datedeladerniereassembleegeneraleannuelle
        ),
      },
      vdl_nombredemembresactifsauseindelorganisme: {
        value:
          demand.vdl_detailsdelademande
            .vdl_nombredemembresactifsauseindelorganisme ?? "",
      },
      vdl_duneconcertation: {
        value: demand.vdl_detailsdelademande.vdl_duneconcertation ?? "",
      },
      vdl_dunregroupement: {
        value: demand.vdl_detailsdelademande.vdl_dunregroupement ?? "",
      },
      vdl_dunefederation: {
        value: demand.vdl_detailsdelademande.vdl_dunefederation ?? "",
      },
      vdl_produisezvousannuellementdesprevisionsbudgeta: {
        value:
          demand.vdl_detailsdelademande
            .vdl_produisezvousannuellementdesprevisionsbudgeta ?? false,
      },
      vdl_vosderniersetatsfinanciersontilsfaitlobjet: {
        value:
          demand.vdl_detailsdelademande
            .vdl_vosderniersetatsfinanciersontilsfaitlobjet ?? false,
      },
      vdl_datedefindevotreexercicefinancierannuel: {
        value: validateDateString(
          demand.vdl_detailsdelademande
            .vdl_datedefindevotreexercicefinancierannuel
        ),
      },
      vdl_audit: {
        value: demand.vdl_detailsdelademande.vdl_audit ?? false,
      },
      vdl_missiondexamen: {
        value: demand.vdl_detailsdelademande.vdl_missiondexamen ?? false,
      },
      vdl_verificationcomptable: {
        value: demand.vdl_detailsdelademande.vdl_verificationcomptable ?? false,
      },
      vdl_resolutionduconseildadministration: {
        value: dynamicsFileToFileType(
          demand.vdl_detailsdelademande.vdl_resolutionduconseildadministration
        ),
        required:
          demand.vdl_detailsdelademande.vdl_admissibiliterenouvellement !==
          948300001,
      },
      vdl_reglementsgeneraux: {
        value: dynamicsFileToFileType(
          demand.vdl_detailsdelademande.vdl_reglementsgeneraux
        ),
      },

      vdl_procesverbauxdesagaetags: {
        value: dynamicsFileToFileType(
          demand.vdl_detailsdelademande.vdl_procesverbauxdesagaetags
        ),
        required:
          demand.vdl_detailsdelademande.vdl_admissibiliterenouvellement !==
          948300001,
      },
      vdl_lettrespatentesetcharte: {
        value: dynamicsFileToFileType(
          demand.vdl_detailsdelademande.vdl_lettrespatentesetcharte
        ),
      },
      vdl_rapportdactivite: {
        value: dynamicsFileToFileType(
          demand.vdl_detailsdelademande.vdl_rapportdactivite
        ),
        required:
          demand.vdl_detailsdelademande.vdl_admissibiliterenouvellement !==
          948300001,
      },
      vdl_etatsfinanciers: {
        value: dynamicsFileToFileType(
          demand.vdl_detailsdelademande.vdl_etatsfinanciers
        ),
        required:
          demand.vdl_detailsdelademande.vdl_admissibiliterenouvellement !==
          948300001,
      },
    })
  );

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      await handleSimpleFormSrollToError(errors[errors.length - 1]);
      console.log(errors);
      return;
    }

    const _demand: IncidentAdmissibilityPatch = {
      title: fieldsGeneral.title.value,
      _vdl_contactprincipal_value: fieldsGeneral.vdl_contactprincipal.value,
      idtypedeservice: demand.idtypedeservice,
      vdl_detailsdelademande: {
        vdl_dorganismeabutnonlucratif:
          fields.vdl_dorganismeabutnonlucratif.value,
        vdl_dorganismedebienfaisanceenregistre:
          fields.vdl_dorganismedebienfaisanceenregistre.value,
        vdl_etesvousunchapitrelavalloisduneinstancepro:
          fields.vdl_etesvousunchapitrelavalloisduneinstancepro.value,
        vdl_siouiquelestlenometleneqdelorg:
          fields.vdl_siouiquelestlenometleneqdelorg.value,
        vdl_datedeladeclarationaureq: dayjsToUtcString(
          fields.vdl_datedeladeclarationaureq.value
        ),
        vdl_aucuneassurance: fields.vdl_aucuneassurance.value,
        vdl_assurancedesadministrateurs:
          fields.vdl_assurancedesadministrateurs.value,
        vdl_assurancedesbiens: fields.vdl_assurancedesbiens.value,
        vdl_assuranceresponsabilitecivile:
          fields.vdl_assuranceresponsabilitecivile.value,
        vdl_assuranceadministrateursetdirigeants:
          fields.vdl_assuranceadministrateursetdirigeants.value,
        vdl_nomdelacompagniedassurance:
          fields.vdl_nomdelacompagniedassurance.value,
        vdl_numerodelapolicedassurance:
          fields.vdl_numerodelapolicedassurance.value,
        vdl_datedecheancedelapolicedassurance: dayjsToUtcString(
          fields.vdl_datedecheancedelapolicedassurance.value
        ),

        vdl_missionetobjectif: fields.vdl_missionetobjectif.value,

        vdl_realitebesoinsoutien: fields.vdl_realitebesoinsoutien.value,

        vdl_pointsdeservicealaval: fields.vdl_pointsdeservicealaval.value,
        vdl_siouiaquelendroit: fields.vdl_siouiaquelendroit.value,
        vdl_dansqueldomainedactivitevotreorganismeinter:
          fields.vdl_dansqueldomainedactivitevotreorganismeinter.value,
        vdl_siautresprecisez: fields.vdl_siautresprecisez.value,
        vdl_principauxserviceouactivites:
          fields.vdl_principauxserviceouactivites.value,
        vdl_desregroupementsetoudesorganismes:
          fields.vdl_desregroupementsetoudesorganismes.value,
        vdl_territoiresdesservisparlorganisme2:
          fields.vdl_territoiresdesservisparlorganisme2.value,
        vdl_siailleursprecisez: fields.vdl_siailleursprecisez.value,

        vdl_nombredeparticipantsauxactivites:
          fields.vdl_nombredeparticipantsauxactivites.value,
        vdl_typedeclientele: fields.vdl_typedeclientele.value,

        vdl_prescolaire0a4ans: fields.vdl_prescolaire0a4ans.value,
        vdl_enfants5a12ans: fields.vdl_enfants5a12ans.value,
        vdl_adolescents13a17ans: fields.vdl_adolescents13a17ans.value,
        vdl_adultes18a64ans: fields.vdl_adultes18a64ans.value,
        vdl_aines65ansetplus65: fields.vdl_aines65ansetplus65.value,

        vdl_politiquedeverification: fields.vdl_politiquedeverification.value,
        vdl_entente: fields.vdl_entente.value,
        vdl_codedeconduite: fields.vdl_codedeconduite.value,
        vdl_formationsurladoptiondecomportementsadequats:
          fields.vdl_formationsurladoptiondecomportementsadequats.value,
        vdl_codedeconduitedelorganisation:
          fields.vdl_codedeconduitedelorganisation.value,
        vdl_codeetsignatureduncontratethique:
          fields.vdl_codeetsignatureduncontratethique.value,
        vdl_verificationantecedentjudiciaire:
          fields.vdl_verificationantecedentjudiciaire.value,
        vdl_mecanismedegestionderisqueencasdedoute:
          fields.vdl_mecanismedegestionderisqueencasdedoute.value,
        vdl_utilisationdelaplateformejebenevoleca:
          fields.vdl_utilisationdelaplateformejebenevoleca.value,
        vdl_jeveuxplusdinformationacesujet:
          fields.vdl_jeveuxplusdinformationacesujet.value,

        vdl_nombrederencontresduca: fields.vdl_nombrederencontresduca.value,
        vdl_nombredepresencesalaga: fields.vdl_nombredepresencesalaga.value,

        vdl_datedeladerniereassembleegeneraleannuelle: dayjsToUtcString(
          fields.vdl_datedeladerniereassembleegeneraleannuelle.value
        ),
        vdl_nombredemembresactifsauseindelorganisme:
          fields.vdl_nombredemembresactifsauseindelorganisme.value,
        vdl_duneconcertation: fields.vdl_duneconcertation.value,
        vdl_dunregroupement: fields.vdl_dunregroupement.value,
        vdl_dunefederation: fields.vdl_dunefederation.value,

        vdl_produisezvousannuellementdesprevisionsbudgeta:
          fields.vdl_produisezvousannuellementdesprevisionsbudgeta.value,
        vdl_vosderniersetatsfinanciersontilsfaitlobjet:
          fields.vdl_vosderniersetatsfinanciersontilsfaitlobjet.value,
        vdl_datedefindevotreexercicefinancierannuel: dayjsToUtcString(
          fields.vdl_datedefindevotreexercicefinancierannuel.value
        ),

        vdl_audit: fields.vdl_audit.value,
        vdl_missiondexamen: fields.vdl_missiondexamen.value,
        vdl_verificationcomptable: fields.vdl_verificationcomptable.value,
      },
    };

    if (fields.vdl_resolutionduconseildadministration.changed) {
      if (fields.vdl_resolutionduconseildadministration.value) {
        const pieceJointeToBase = await fileToBase64(
          fields.vdl_resolutionduconseildadministration.value
        );
        if (pieceJointeToBase) {
          _demand.vdl_detailsdelademande.vdl_resolutionduconseildadministration =
            {
              filename:
                fields.vdl_resolutionduconseildadministration.value.name,
              content: pieceJointeToBase,
            };
        }
      }
    }

    if (fields.vdl_reglementsgeneraux.changed) {
      if (fields.vdl_reglementsgeneraux.value) {
        const pieceJointeToBase = await fileToBase64(
          fields.vdl_reglementsgeneraux.value
        );
        if (pieceJointeToBase) {
          _demand.vdl_detailsdelademande.vdl_reglementsgeneraux = {
            filename: fields.vdl_reglementsgeneraux.value.name,
            content: pieceJointeToBase,
          };
        }
      }
    }

    if (fields.vdl_procesverbauxdesagaetags.changed) {
      if (fields.vdl_procesverbauxdesagaetags.value) {
        const pieceJointeToBase = await fileToBase64(
          fields.vdl_procesverbauxdesagaetags.value
        );
        if (pieceJointeToBase) {
          _demand.vdl_detailsdelademande.vdl_procesverbauxdesagaetags = {
            filename: fields.vdl_procesverbauxdesagaetags.value.name,
            content: pieceJointeToBase,
          };
        }
      }
    }

    if (fields.vdl_lettrespatentesetcharte.changed) {
      if (fields.vdl_lettrespatentesetcharte.value) {
        const pieceJointeToBase = await fileToBase64(
          fields.vdl_lettrespatentesetcharte.value
        );
        if (pieceJointeToBase) {
          _demand.vdl_detailsdelademande.vdl_lettrespatentesetcharte = {
            filename: fields.vdl_lettrespatentesetcharte.value.name,
            content: pieceJointeToBase,
          };
        }
      }
    }

    if (fields.vdl_rapportdactivite.changed) {
      if (fields.vdl_rapportdactivite.value) {
        const pieceJointeToBase = await fileToBase64(
          fields.vdl_rapportdactivite.value
        );
        if (pieceJointeToBase) {
          _demand.vdl_detailsdelademande.vdl_rapportdactivite = {
            filename: fields.vdl_rapportdactivite.value.name,
            content: pieceJointeToBase,
          };
        }
      }
    }

    if (fields.vdl_etatsfinanciers.changed) {
      if (fields.vdl_etatsfinanciers.value) {
        const pieceJointeToBase = await fileToBase64(
          fields.vdl_etatsfinanciers.value
        );
        if (pieceJointeToBase) {
          _demand.vdl_detailsdelademande.vdl_etatsfinanciers = {
            filename: fields.vdl_etatsfinanciers.value.name,
            content: pieceJointeToBase,
          };
        }
      }
    }

    await update(_demand);
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_typededemande.vdl_lienhautduformulaireportail}
      parentIncidentId={demand._parentcaseid_value}
    >
      <SimpleFields
        sectionsBackground="common.white"
        updateRefs
        fields={fields}
        setFields={setFields}
        sections={
          demand.vdl_detailsdelademande.vdl_admissibiliterenouvellement ===
          948300001
            ? editDemandAdmissiblityRenouvellementSections
            : editDemandAdmissiblitySections
        }
      />
    </EditDemandLayout>
  );
};

export default Admissibility;
