import { Box, Typography } from "@mui/material";
import React from "react";
import BoxResponsivePadding from "../../../../common/boxes/BoxResponsivePadding";
import ClickableIcon from "../../../../common/buttons/icons/ClickableIcon";
import InfoIcon from "@mui/icons-material/Info";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  dateTimeStringToStringUTC,
} from "../../../../../data/common/fieldToString";
import {  IncidentSpecialEventsDateRow } from "../../../../../data/Incident/types/specialEvents/dates";

type Props = {
  date:
    | IncidentSpecialEventsDateRow
};
const DateCard = ({ date  }: Props) => {
  return (
    <BoxResponsivePadding
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
      bgcolor="background.default"
      rowGap="16px"
      columnGap="24px"
    >
      <Typography fontWeight="bold">
        {date.vdl_description}
      </Typography>

      <Box>
        <Typography fontWeight="bold">{`${dateTimeStringToStringUTC(
          date.vdl_dateetheurededebut
        )}`}</Typography>
        <Typography fontWeight="bold">{`au ${dateTimeStringToStringUTC(
          date.vdl_dateetheuredefin
        )}`}</Typography>
      </Box>
      <Box></Box>
    </BoxResponsivePadding>
  );
};

export default DateCard;
