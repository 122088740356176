import React from "react";
import { OptionSetInput } from "../../../types";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  IconButton,
} from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material";
import { isNumber } from "../../../utils/isNumber";

type Props = {
  field: OptionSetInput;
  fieldName: string;
  handleChange: (field: OptionSetInput) => void;
  disabled: boolean;
};

const FormOptionSetInput = ({
  field,
  fieldName,
  handleChange,
  disabled,
}: Props) => {
  function handleInputChange(event: SelectChangeEvent) {
    const { value } = event.target;
    let _field = { ...field };
    if (field.required && value !== "") {
      _field.error = "";
    }
    if (isNumber(value)) {
      _field.value = parseInt(value, 10);
    }

    handleChange(_field);
  }
  function handleEmpty() {
    let _field = { ...field };
    _field.value = null;
    handleChange(_field);
  }
  function handleBlur(
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) {
    let _field = { ...field };
    _field.error = "";
    if (field.required && (!field.value || field.value === null)) {
      _field.error = "Ce champ est requis";
    }
    handleChange(_field);
  }

  return (
    <FormControl
      variant="outlined"
      fullWidth
      required={field.required}
      disabled={disabled}
      error={!(field.error == "")}
      sx={{ maxWidth: "100%" }}
    >
      <InputLabel id={fieldName}>{field.label}</InputLabel>
      <Select
        labelId={fieldName}
        name={fieldName}
        value={field.value ? field.value.toString() : ""}
        onChange={handleInputChange}
        label={field.label}
        onBlur={handleBlur}
        sx={{
          maxWidth: "100%",
          overflow: "hidden",
        }}
        SelectDisplayProps={{ style: { maxWidth: "100%" } }}
        renderValue={(value: string) => (
          <span
            style={{
              maxWidth: "50vw",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {field.value ? field.choices[field.value] : ""}
          </span>
        )}
        endAdornment={
          field.value && field.clearable ? (
            <IconButton
              size="small"
              sx={{
                position: "absolute",
                right: 32,
                visibility: field.value ? "visible" : "hidden",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                handleEmpty();
              }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ) : null
        }
      >
        {(field.sortBy === undefined
          ? Object.entries(field.choices)
          : Object.entries(field.choices).sort((a, b) => {
              if (field.sortBy === "int") {
                return parseInt(a[0]) - parseInt(b[0]);
              }

              const textA = a[1].toString().toLowerCase();
              const textB = b[1].toString().toLowerCase();
              return textA.localeCompare(textB, "fr", { sensitivity: "base" });
            })
        ).map(([key, val]) => (
          <MenuItem
            onClick={() =>
              key === field.value?.toString() ? handleEmpty() : null
            }
            key={key}
            value={key}
          >
            {val}
          </MenuItem>
        ))}
      </Select>
      {field.error || field.helperText ? (
        <FormHelperText>
          {field.error ? field.error : field.helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default FormOptionSetInput;
