import { Dialog, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { IncidentSpecialEventsDateBase } from "../../../../../../data/Incident/types/specialEvents/dates";
import { useSnackbar } from "../../../../../../hooks/useSnackbar";
import CustomButton from "../../../../../common/buttons/CustomButton";
import CustomDialogHeader from "../../../../../dialog/CustomDialogHeader/CustomDialogHeader";
import SimpleFields from "../../../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../../../form/SimpleForm/handleSimpleFormSubmit";
import {
  EditDemandSpecialEventsDateFields,
  editDemandSpecialEventsDateFields,
} from "../../../../../../data/Incident/forms/specialEvents/dates";
import SimpleFormWithSections from "../../../../../form/SimpleForm/SimpleFormWithSections";
import { dayjsToUtcString } from "../../../../../../data/common/dayjsToUtcString";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addDate(newDate: IncidentSpecialEventsDateBase): void;
};

const DatesDialog = ({ open, setOpen, addDate }: Props) => {

  const { showSnackbar } = useSnackbar();

  function handleSubmit(fields: EditDemandSpecialEventsDateFields) {
    try {

        addDate({
          vdl_description: fields.vdl_description.value,
          vdl_dateetheurededebut: dayjsToUtcString(fields.vdl_dateetheurededebut.value)??"",
          vdl_dateetheuredefin: dayjsToUtcString(fields.vdl_dateetheuredefin.value)??"",
        });
    } catch (e) {
      showSnackbar("Erreur dans l'ajout de la date", "error");
    }
  }
  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <CustomDialogHeader title="Nouvelle date" setOpen={setOpen} />
      <Box>
        <SimpleFormWithSections
          fields={editDemandSpecialEventsDateFields}
          handleSuccess={handleSubmit}
          submitText={"Ajouter"}
        />
      </Box>
    </Dialog>
  );
};

export default DatesDialog;
