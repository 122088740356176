import { QueryError, ErrorType, OperationType } from "../../types";
import fetchMsal from "../fetchMsal";
import { DocumentFile } from "./models";

const getIncidentFiles = async (
  incidentId?: string
): Promise<DocumentFile[]> => {
  try {
    if (!incidentId)
      throw new QueryError(
        ErrorType.NOT_FOUND,
        "Identifiant de la demande est invalide."
      );

    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_demand_files/${incidentId}`;

    const result = await fetchMsal("GET", endpoint);

    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    }

    return await result.json();
  } catch (e) {
    throw QueryError.TransformUnknown(
      e,
      "Fichiers de la demande",
      OperationType.RETRIEVEMULTIPLE
    );
  }
};

const getFiles = async (): Promise<DocumentFile[]> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_files`;

    const result = await fetchMsal("GET", endpoint);

    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    }

    return await result.json();
  } catch (e) {
    throw QueryError.TransformUnknown(
      e,
      "Fichiers",
      OperationType.RETRIEVEMULTIPLE
    );
  }
};

export { getIncidentFiles, getFiles };
