import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import AttachmentCardSkeleton from "./AttachmentCardSkeleton";
import { useParams } from "react-router-dom";
import { getIncidentFiles } from "../../../../../data/Document/queries";
import { DocumentFile, DocumentRow } from "../../../../../data/Document/models";
import { ErrorType, QueryError } from "../../../../../types";
import { useQuery } from "@tanstack/react-query";
import QueryErrorMessageInner from "../../../../common/error/QueryErrorMessageInner";
import fetchMsal from "../../../../../data/fetchMsal";
import { useState } from "react";
import DownloadableFile from "../../../Documents/DownloadableFile";

const AttachmentCard = () => {
  const { id } = useParams();

  const { data, error, isLoading, isFetching } = useQuery<
    DocumentFile[],
    QueryError
  >({
    queryKey: ["Demand", id, "Attachments"],
    queryFn: () => getIncidentFiles(id),
    staleTime: 30000,
    gcTime: 30000,
  });

  if (isLoading) return <AttachmentCardSkeleton />;

  if (!data || error || !id)
    return (
      <Card elevation={0} sx={{ bgcolor: "white" }}>
        <CardContent>
          <Box display="flex" flexDirection="column" gap="10px">
            <Typography variant="h6" fontWeight="bold" color="primary">
              Pièces jointes
            </Typography>
            <Box display="grid" rowGap="6px">
              <QueryErrorMessageInner error={error} />
            </Box>
          </Box>
        </CardContent>
      </Card>
    );

  return (
    <Card elevation={0} sx={{ bgcolor: "white" }}>
      <CardContent>
        <Box display="flex" flexDirection="column" gap="10px">
          <Typography variant="h6" fontWeight="bold" color="primary">
            Pièces jointes
          </Typography>
          <Box display="grid" rowGap="6px">
            {data.length > 0 ? (
              data.map((demandFile, index) => (
                <DownloadableFile file={demandFile} key={index} />
              ))
            ) : (
              <Typography>Aucune pièce jointe.</Typography>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AttachmentCard;
