import { Dayjs } from "dayjs";
import {
  AutoCompleteInput,
  CheckBoxInput,
  DateInput,
  DateTimeInput,
  DropDownChoice,
  DropDownOptionSetInput,
  FormFields,
  FormSection,
  MultilineInput,
  NumberInput,
  TextInput,
} from "../../../types";
import { electronicPanelsDisplayNames } from "../displayNames";

export type EditDemandElectronicPanelsFields = {
  vdl_datededebutdelaffichage: DateInput;
  vdl_datedefindelaffichage: DateInput;
  vdl_quoi: TextInput;
  vdl_qui: TextInput;
  vdl_ou: TextInput;
  vdl_telephonepourinformationsurlevenement: TextInput;
};

const editDemandElectronicPanelsFields: EditDemandElectronicPanelsFields = {
  vdl_datededebutdelaffichage: {
    type: "date",
    error: "",
    value: null,
    required: false,
    disablePast: true,
    label: electronicPanelsDisplayNames.vdl_datededebutdelaffichage,
  },
  vdl_datedefindelaffichage: {
    type: "date",
    error: "",
    value: null,
    required: false,
    disablePast: true,
    disabledCondition: isDateFinDisabled,
    label: electronicPanelsDisplayNames.vdl_datedefindelaffichage,
    minDate: (fields: FormFields) =>
      fields.vdl_datededebutdelaffichage.value
        ? (fields.vdl_datededebutdelaffichage.value as Dayjs)
        : undefined,
  },
  vdl_quoi: {
    type: "text",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    label: electronicPanelsDisplayNames.vdl_quoi,
  },
  vdl_qui: {
    type: "text",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    label: electronicPanelsDisplayNames.vdl_qui,
  },
  // vdl_quanddatesetheures: {
  //   type: "text",
  //   error: "",
  //   value: "",
  //   required: true,
  //   fillLine: true,
  //   label: electronicPanelsDisplayNames.vdl_quanddatesetheures,
  // },
  vdl_ou: {
    type: "text",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    label: electronicPanelsDisplayNames.vdl_ou,
  },
  vdl_telephonepourinformationsurlevenement: {
    type: "text",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    label:
      electronicPanelsDisplayNames.vdl_telephonepourinformationsurlevenement,
  },
};

function isDateFinDisabled(fields: FormFields): boolean {
  try {
    const _fields2 = <EditDemandElectronicPanelsFields>fields;
    return _fields2.vdl_datededebutdelaffichage.value === undefined;
  } catch (e) {
    return true;
  }
}

const editDemandElectronicPanelsDateSection: FormSection<EditDemandElectronicPanelsFields> =
  {
    fields: ["vdl_datededebutdelaffichage", "vdl_datedefindelaffichage"],
    title: "Dates d'affichages",
  };
const editDemandElectronicPanelsInfoSection: FormSection<EditDemandElectronicPanelsFields> =
  {
    fields: [
      "vdl_quoi",
      "vdl_qui",
      // "vdl_quanddatesetheures",
      "vdl_ou",
      "vdl_telephonepourinformationsurlevenement",
    ],
    title: "Informations",
  };
const editDemandElectronicPanelsSections: FormSection<EditDemandElectronicPanelsFields>[] =
  [
    editDemandElectronicPanelsDateSection,
    editDemandElectronicPanelsInfoSection,
  ];

export { editDemandElectronicPanelsFields, editDemandElectronicPanelsSections };
