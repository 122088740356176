import { Box, Typography } from "@mui/material";
import React from "react";
import BoxResponsivePadding from "../../../../common/boxes/BoxResponsivePadding";
import ClickableIcon from "../../../../common/buttons/icons/ClickableIcon";
import InfoIcon from "@mui/icons-material/Info";
import DeletIcon from "@mui/icons-material/Delete";
import {
  dateStringToLongWithDayStringUTC, dateTimeStringToStringUTC,
} from "../../../../../data/common/fieldToString";
import { IncidentSpecialEventsDateBase, IncidentSpecialEventsDateRow } from "../../../../../data/Incident/types/specialEvents/dates";

type Props = {
  date:
    | IncidentSpecialEventsDateBase //Existantes
    | IncidentSpecialEventsDateRow; //Nouvelles
  deleteDate(): void;
};
const DateCard = ({ date, deleteDate  }: Props) => {
  return (
    <BoxResponsivePadding
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
      bgcolor="background.default"
      rowGap="16px"
      columnGap="24px"
    >
      <Typography fontWeight="bold">
        {date.vdl_description}
      </Typography>

      <Box>
        <Typography fontWeight="bold">{`${dateTimeStringToStringUTC(
          date.vdl_dateetheurededebut
        )}`}</Typography>
        <Typography fontWeight="bold">{`au ${dateTimeStringToStringUTC(
          date.vdl_dateetheuredefin
        )}`}</Typography>
      </Box>
      <Box>
        <ClickableIcon
          backgroundColor="background.paper"
          icon={<DeletIcon />}
          handleClick={deleteDate}
        />
      </Box>
    </BoxResponsivePadding>
  );
};

export default DateCard;
