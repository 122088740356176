import { Dialog, Box, Typography, useTheme } from "@mui/material";
import {
  IncidentFilmingRow,
  IncidentSpecialEventRow,
} from "../../../../data/Incident/models";
import Transition from "../../../common/animation/DialogTransition";
import { ActivityTypeRow } from "../../../../data/ActivityType/models";
import {
  ActivityPatch,
  ActivityPost,
  ActivityRow,
} from "../../../../data/Activity/models";
import {
  BaseInput,
  FormField,
  FormFields,
  FormFieldsBuilder,
  FormFieldsPartial,
  QueryError,
} from "../../../../types";
import { ActivityTypeFieldRow } from "../../../../data/ActivityTypeField/models";
import {
  EditActivityFields,
  editActivityFields,
} from "../../../../data/Activity/forms/edit";
import { validateDateString } from "../../../../data/common/validateDateString";
import { buildEmptyFile } from "../../../../data/common/buildFile";
import { buildPartialFormObj } from "../../../form/SimpleForm/buildForm";
import SimpleFormWithSections from "../../../form/SimpleForm/SimpleFormWithSections";
import { fileToBase64 } from "../../../../data/common/fileToBase64";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createContact } from "../../../../data/Contact/queries";
import {
  createActivity,
  updateActivity,
} from "../../../../data/Activity/queries";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import SimpleFormWithSectionsWithState from "../../../form/SimpleForm/SimpleFormWithSectionsWithState";
import { SetStateAction, useState } from "react";
import BoxResponsivePadding from "../../../common/boxes/BoxResponsivePadding";
import dayjs from "dayjs";
import { dayjsToUtcString } from "../../../../data/common/dayjsToUtcString";

type Props =
  | {
      incidentId?: undefined;
      activityRow: ActivityRow;
      activityType: ActivityTypeRow;
      handleCloseForm: () => void;
    }
  | {
      activityRow?: undefined;
      incidentId: string;
      activityType: ActivityTypeRow;
      handleCloseForm: () => void;
    };

const activitiesFieldsToFormFields = (
  activityType: ActivityTypeRow,
  setDelayMessage: React.Dispatch<SetStateAction<string | undefined>>,
  activity?: ActivityRow
): Partial<FormFieldsBuilder<EditActivityFields>> => {
  const fields = activityType.champs;

  const _fields = fields.reduce((acc, item) => {
    if (!activity) {
      if (item.vdl_nominterne in editActivityFields) {
        const _formField: BaseInput = {
          ...editActivityFields[
            item.vdl_nominterne as keyof typeof editActivityFields
          ],
          helperText: item.vdl_precisionschamp,
          label: item.vdl_libelleauportail,
          error: "",
          required: item.vdl_requis ?? false,
        };

        const _fieldKey =
          item.vdl_nominterne as keyof typeof editActivityFields;
        acc[item.vdl_nominterne] = {
          ..._formField,
          value: editActivityFields[_fieldKey].value,
        } as Partial<FormField>;
      }

      return acc;
    }
    if (
      !(item.vdl_nominterne in activity) ||
      !(item.vdl_nominterne in editActivityFields)
    ) {
      return acc;
    }
    const activityValue = activity[item.vdl_nominterne as keyof ActivityRow];
    const activityValueIsNull = activityValue === null;

    const _fieldKey = item.vdl_nominterne as keyof typeof editActivityFields;

    const _formField: BaseInput = {
      ...editActivityFields[_fieldKey],
      helperText: item.vdl_precisionschamp,
      label: item.vdl_libelleauportail,
      error: "",
      required: item.vdl_requis ?? false,
    };

    switch (item.vdl_type) {
      case 948300000:
        if (
          (activityValueIsNull || typeof activityValue === "string") &&
          editActivityFields[_fieldKey].type === "text"
        ) {
          acc[_fieldKey] = { ..._formField, value: activityValue ?? "" };
        }
        break;
      case 948300001:
        if (
          (activityValueIsNull || typeof activityValue === "string") &&
          editActivityFields[_fieldKey].type === "multiline"
        ) {
          acc[_fieldKey] = { ..._formField, value: activityValue ?? "" };
        }
        break;
      case 948300002:
        if (
          (activityValueIsNull || typeof activityValue === "string") &&
          editActivityFields[_fieldKey].type === "datetime"
        ) {
          acc[_fieldKey] = {
            ..._formField,
            value: validateDateString(activityValue),
          };
        }
        break;
      /*case 948300003:
        if (
          typeof activityValue === "string" &&
          editActivityFields[_fieldKey].type === "date"
        ) {
          acc[_fieldKey] = {
            ..._formField,
            value: validateDateString(activityValue),
          };
        }
        break;*/
      case 948300004:
        if (
          (activityValueIsNull || typeof activityValue === "number") &&
          editActivityFields[_fieldKey].type === "number"
        ) {
          acc[_fieldKey] = {
            ..._formField,
            value: activityValue,
          };
        }
        break;
      /*case 948300005:
        if (typeof value === "number") {
          return value.toString();
        }
        break;*/

      case 948300006:
        if (activityValueIsNull || typeof activityValue === "boolean") {
          acc[_fieldKey] = {
            ..._formField,
            value: activityValue ?? false,
          };
        }
        break;
      case 948300008: //File
        if (
          (activityValueIsNull || typeof activityValue === "string") &&
          editActivityFields[_fieldKey].type === "file"
        ) {
          const fileName = activity[(_fieldKey + "_name") as keyof ActivityRow];
          if (typeof fileName === "string") {
            acc[_fieldKey] = {
              ..._formField,
              value: buildEmptyFile(fileName),
            };
          } else {
            acc[_fieldKey] = { ..._formField };
          }
        }
        break;
      case 948300007: //Options
        if (
          (activityValueIsNull || typeof activityValue === "number") &&
          editActivityFields[_fieldKey].type === "options"
        ) {
          acc[_fieldKey] = {
            ..._formField,
            value: activityValue,
          };
        }
        break;
      case 948300009: //Options - plusieurs choix
        if (
          (activityValueIsNull || Array.isArray(activityValue)) &&
          editActivityFields[_fieldKey].type === "multipleoptions"
        ) {
          acc[_fieldKey] = {
            ..._formField,
            value: activityValue === null ? [] : activityValue,
          };
        }
        break;
    }
    return acc;
  }, {} as FormFieldsPartial) as Partial<FormFieldsBuilder<EditActivityFields>>;

  _fields.vdl_debut = {
    value: validateDateString(activity?.vdl_debut),
    onChangeEvent: (fields: FormFields) => {
      const _fields2 = fields as EditActivityFields;
      let newMessage: string | undefined = undefined;
      console.log(activityType.vdl_delairequis);

      if (_fields2.vdl_debut) {
        const _date = _fields2.vdl_debut;
        if (_date.value != null) {
          const days = _date.value.diff(dayjs.utc(), "days");
          
          if (days < activityType.vdl_delairequis) {
            newMessage =
              "Les délais minimums sont dépassés.\nCe type d'activité requiert un délai minimum de " +
              activityType.vdl_delairequis +
              " jours.";
          }
        }
      }
      setDelayMessage(newMessage);
      return undefined;
    },
  };
  _fields.vdl_fin = {
    value: validateDateString(activity?.vdl_fin),
  };
  _fields.vdl_details = {
    value: activity?.vdl_details ?? "",
  };

  return _fields;
};

const ActivityFormDialog = ({
  activityType,
  activityRow,
  incidentId,
  handleCloseForm,
}: Props) => {
  const { showSnackbar, showErrorSnackbar } = useSnackbar();
  const [delayMessage, setDelayMessage] = useState<string | undefined>(
    undefined
  );
  const [fields, setFields] = useState(
    buildPartialFormObj(
      editActivityFields,
      activitiesFieldsToFormFields(activityType, setDelayMessage, activityRow)
    )
  );
  const queryClient = useQueryClient();
  const theme = useTheme();

  const createMutation = useMutation<
    string | undefined,
    QueryError,
    ActivityPost
  >({
    mutationFn: (_activity) => createActivity(_activity),
    onSuccess: (data, activityPost) => {
      queryClient.invalidateQueries({
        queryKey: ["Activities", activityPost._vdl_demandeassociee_value],
      });
      showSnackbar("Activité créée", "success");
      handleCloseForm();
    },
    onError: (error: QueryError) => {
      showErrorSnackbar(
        "Erreur dans la création de l'activité.",
        "error",
        error
      );
    },
  });

  const updateMutation = useMutation<
    void,
    QueryError,
    { activityId: string; activity: ActivityPatch }
  >({
    mutationFn: ({ activityId, activity }) =>
      updateActivity(activityId, activity),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["Activities"] });
      showSnackbar("Activité modifiée", "success");
      handleCloseForm();
    },
    onError: (error: QueryError) => {
      showErrorSnackbar(
        "Erreur dans la modification de l'activité.",
        "error",
        error
      );
    },
  });

  async function handleSubmit() {
    const _activityPatch: ActivityPatch = {
      vdl_adressetoilettes: fields.vdl_adressetoilettes.value,
      vdl_attestationassurancerespo: fields.vdl_attestationassurancerespo.value,
      vdl_attestationfournisseurequipement:
        fields.vdl_attestationfournisseurequipement.value,
      vdl_attestationoperateur: fields.vdl_attestationoperateur.value,
      vdl_bacroulantbrun240l: fields.vdl_bacroulantbrun240l.value,
      vdl_datesethoraire: fields.vdl_datesethoraire.value,
      vdl_document1: null,
      vdl_document2: null,
      vdl_document3: null,
      vdl_duobacsroulants240l: fields.vdl_duobacsroulants240l.value,
      vdl_duobarils205l: fields.vdl_duobarils205l.value,
      vdl_debut: dayjsToUtcString(fields.vdl_debut.value),
      vdl_detailsaffichages: fields.vdl_detailsaffichages.value,
      vdl_detailsspecifiques: fields.vdl_detailsspecifiques.value,
      vdl_detailsstructures: fields.vdl_detailsstructures.value,
      vdl_fin: dayjsToUtcString(fields.vdl_fin.value),
      vdl_adressesite: fields.vdl_adressesite.value,
      vdl_nombreparticipants: fields.vdl_nombreparticipants.value,
      vdl_nopointsdevente: fields.vdl_nopointsdevente.value,
      vdl_nbtoilette_mobilitereduite:
        fields.vdl_nbtoilette_mobilitereduite.value,
      vdl_nbtoilette: fields.vdl_nbtoilette.value,
      vdl_profitsactivite: fields.vdl_profitsactivite.value,
      vdl_precisionlieusoutieneco: fields.vdl_precisionlieusoutieneco.value,
      vdl_detailssautresaffichages: fields.vdl_detailssautresaffichages.value,
      vdl_raison: fields.vdl_raison.value,
      vdl_details: fields.vdl_details.value,
      vdl_ruedirection: fields.vdl_ruedirection.value,
      vdl_slimjimcompost: fields.vdl_slimjimcompost.value,
      vdl_slimjimnoir90l: fields.vdl_slimjimnoir90l.value,
      vdl_slimjimrecyclage: fields.vdl_slimjimrecyclage.value,
      vdl_detailssautresstructures: fields.vdl_detailssautresstructures.value,
      vdl_trajet: fields.vdl_trajet.value,
      vdl_typeaccessoirefeu: fields.vdl_typeaccessoirefeu.value,
      vdl_typeappareilcombustion: fields.vdl_typeappareilcombustion.value,
      vdl_typeartistedefeu: fields.vdl_typeartistedefeu.value,
      vdl_typeactions: fields.vdl_typeactions.value,
      vdl_typecombustible: fields.vdl_typecombustible.value,
      vdl_typedefeu: fields.vdl_typedefeu.value,
      vdl_typedeplacement: fields.vdl_typedeplacement.value,
      vdl_typefermeturederue: fields.vdl_typefermeturederue.value,
      vdl_typevoiepublique: fields.vdl_typevoiepublique.value,
      vdl_typepyro: fields.vdl_typepyro.value,
      vdl_typeserviceeco: fields.vdl_typeserviceeco.value,
      vdl_typesaffichages: fields.vdl_typesaffichages.value,
      vdl_typestructure: fields.vdl_typestructure.value,
      vdl_ventedemandeur: fields.vdl_ventedemandeur.value,
      vdl_ventetiers: fields.vdl_ventetiers.value,
    };

    if (fields.vdl_document1.changed) {
      if (fields.vdl_document1.value) {
        const pieceJointeToBase = await fileToBase64(
          fields.vdl_document1.value
        );
        if (pieceJointeToBase) {
          _activityPatch.vdl_document1 = {
            filename: fields.vdl_document1.value.name,
            content: pieceJointeToBase,
          };
        }
      }
    }

    if (fields.vdl_document2.changed) {
      if (fields.vdl_document2.value) {
        const pieceJointeToBase = await fileToBase64(
          fields.vdl_document2.value
        );
        if (pieceJointeToBase) {
          _activityPatch.vdl_document2 = {
            filename: fields.vdl_document2.value.name,
            content: pieceJointeToBase,
          };
        }
      }
    }

    if (fields.vdl_document3.changed) {
      if (fields.vdl_document3.value) {
        const pieceJointeToBase = await fileToBase64(
          fields.vdl_document3.value
        );
        if (pieceJointeToBase) {
          _activityPatch.vdl_document3 = {
            filename: fields.vdl_document3.value.name,
            content: pieceJointeToBase,
          };
        }
      }
    }

    if (activityRow) {
      updateMutation.mutate({
        activityId: activityRow.vdl_activite_evenementid,
        activity: _activityPatch,
      });
    } else {
      createMutation.mutate({
        ..._activityPatch,
        _vdl_typedactivite_value: activityType.vdl_typedactiviteid,
        _vdl_demandeassociee_value: incidentId,
      });
    }
  }

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      maxWidth="xl"
      fullWidth
    >
      <BoxResponsivePadding display="flex" flexDirection="column" gap="12px">
        <Box>
          <Typography variant="h4" color="primary">
            {activityType.vdl_typedactivite}
          </Typography>
          <Box
            dangerouslySetInnerHTML={{
              __html: activityType.vdl_description ?? "",
            }}
          />
        </Box>
        <Box>
          {delayMessage ? (
            <Box>
              <Typography fontSize="9pt" color="error.main">
                {delayMessage}
              </Typography>
            </Box>
          ) : null}
        </Box>

        <SimpleFormWithSectionsWithState
          isFormLoading={createMutation.isPending || updateMutation.isPending}
          removeFormPadding
          fields={fields}
          setFields={setFields}
          columns={{ minWidth: 220, count: 2 }}
          sections={[
            {
              padding: "0px",
              fields: [
                "vdl_debut",
                "vdl_fin",
                ...activityType.champs.map(
                  (c) => c.vdl_nominterne as keyof EditActivityFields
                ),
                "vdl_details",
              ],
            },
          ]}
          submitText={activityRow ? "Modifier" : "Initier la création"}
          handleSuccess={handleSubmit}
          cancelText="Annuler"
          handleCancel={handleCloseForm}
        />
      </BoxResponsivePadding>
    </Dialog>
  );
};

export default ActivityFormDialog;
