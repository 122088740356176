import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Typography,
  useTheme,
  Skeleton,
  styled,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import SectionLoading from "../../common/loading/SectionLoading";
import CenteredBox from "../../common/boxes/CenteredBox";

type TopSectionProps = {
  title: string;
  underTitleComponent?: JSX.Element;
  sideTitleComponent?: JSX.Element;
  isLoading?: boolean;
};

type Props = {
  children?: React.ReactNode;
  isLoading?: boolean;
  title: string;
  loadingSpinner?: Boolean;
  globalLoading?: Boolean;
  underTitleComponent?: JSX.Element;
  sideTitleComponent?: JSX.Element;
};

const PageTitleLayout = ({
  children,
  title,
  isLoading,
  underTitleComponent,
  sideTitleComponent,
  loadingSpinner,
  globalLoading,
}: Props) => {
  const theme = useTheme();

  return (
    <Box sx={{ backgroundColor: "bg.light" }}>
      <PageTitleTopSection
        title={title}
        isLoading={isLoading}
        underTitleComponent={underTitleComponent}
        sideTitleComponent={sideTitleComponent}
      />
      {loadingSpinner ? (
        <Box paddingTop={"1rem"}>
          <SectionLoading />
        </Box>
      ) : (
        <Box maxWidth={2000}>{children}</Box>
      )}
      {globalLoading ? (
        <Box
          position="fixed"
          top={0}
          left={0}
          height="100%"
          width="100%"
          bgcolor="RGBA(255,255,255,0.4)"
          zIndex={1000}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            padding="32px"
            borderRadius="50%"
            minHeight="180px"
            minWidth="180px"
          >
            <CircularProgress id="circularProgress" color="primary" />
            <Typography
              sx={{
                padding: "8px",
                background:
                  "linear-gradient(transparent 0%, white 20%, white 80%, transparent 100%);",
                borderRadius: "20px",
              }}
            >
              Chargement...
            </Typography>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

const PageTitleTopSection = ({
  title,
  underTitleComponent,
  sideTitleComponent,
  isLoading,
}: TopSectionProps) => {
  const theme = useTheme();
  const [isSticky, setIsSticky] = useState(false);
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const lastScrollY = useRef(0);
  const ticking = useRef(false);
  const scrollThreshold = 70;
  const scrollBuffer = 20;

  const handleScroll = useCallback(() => {
    if (!ticking.current) {
      window.requestAnimationFrame(() => {
        const currentScrollY = window.scrollY;
        
        if (isSticky && currentScrollY < scrollThreshold - scrollBuffer) {
          setIsSticky(false);
        } else if (!isSticky && currentScrollY > scrollThreshold + scrollBuffer) {
          setIsSticky(true); 
        }
        
        lastScrollY.current = currentScrollY;
        ticking.current = false;
      });
      ticking.current = true;
    }
  }, [isSticky]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <Box
    sx={{
      backgroundColor: "secondary.main",
      position: "sticky",
      top: "60px",
      zIndex: 10,
      transition: "all 0.2s ease-in",
      display: "flex",
      flexDirection: "column",
      padding: isSticky ? "30px 10px 20px 30px" : "60px 10px 30px 30px",
      justifyContent: "center",
      gap: "6px",
      transform: "translateZ(0)",
      willChange: "padding"
    }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          padding: 0,
          alignItems: "center",
          rowGap: "6px",
          columnGap: "20px",
        }}
      >
        <Typography variant="h4" sx={{ color: '#fff' }}>
          {isLoading ? <Skeleton width={"325px"} height={"60px"} /> : title}
        </Typography>
        <Box
          sx={{
            opacity: onlySmallScreen && isSticky ? 0 : 1,
            maxHeight: onlySmallScreen && isSticky ? 0 : "500px",
            transition: "all 0.2s ",
          }}
        >
          {sideTitleComponent ? sideTitleComponent : null}
        </Box>
      </Box>
      <Box
        sx={{
          opacity: isSticky ? 0 : 1,
          maxHeight: isSticky ? 0 : "500px",
          transition: "all 0.2s",
        }}
      >
        {underTitleComponent ? underTitleComponent : null}
      </Box>
    </Box>
  );
};

export default PageTitleLayout;
