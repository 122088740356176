import React, { ReactNode, useState } from "react";
import PageTitleLayout from "../../layouts/PageLayouts/PageTitleLayout";
import BoxResponsivePadding from "../../common/boxes/BoxResponsivePadding";
import { TabContent } from "../../../types";
import SimpleFields from "../../form/SimpleForm/SimpleFields";
import BasicTabs from "../../layouts/AppTabs/AppTabs";
import CustomButton from "../../common/buttons/CustomButton";
import handleSimpleFormSubmit from "../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../hooks/useTabs";
import { Box, Typography, Link as LinkMUI } from "@mui/material";
import { NewDemandFields } from "../../../data/Incident/forms/dialog";
import { routes } from "../../../routes/routes";
import { Link } from "react-router-dom";
import { useSnackbar } from "../../../hooks/useSnackbar";

type Props = {
  fieldsGeneral: NewDemandFields;
  setFieldsGeneral: React.Dispatch<React.SetStateAction<NewDemandFields>>;
  onSubmit: () => Promise<void>;
  children: React.ReactNode;
  topLink?: string | null;
  parentIncidentId?: string | null;
};

const EditDemandLayout = ({
  fieldsGeneral,
  setFieldsGeneral,
  onSubmit,
  children,
  topLink,
  parentIncidentId,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  const tabData: TabContent[] = [
    {
      label: "Informations générales",
      component: (
        <BoxResponsivePadding bgcolor="common.white">
          <SimpleFields
            fields={fieldsGeneral}
            setFields={setFieldsGeneral}
            title="Informations générales"
          />
        </BoxResponsivePadding>
      ),
    },
    {
      label: "Informations spécifiques",
      component: topLink ? (
        <>
          <BoxResponsivePadding bgcolor="common.white" mb="16px">
            <LinkMUI href={topLink} target="_blank" rel="noopener">
              Processus de demande
            </LinkMUI>
          </BoxResponsivePadding>
          {/*<BoxResponsivePadding bgcolor="common.white">
            <Typography variant="h5" color="primary">
              Processus
            </Typography>
            <Link href={topLink} sx={{wordBreak: "break-all"}}>{topLink}</Link>
            <Typography mt={2} color="red">
              *Testeurs: Le lien pourrait être afficher de plusieurs manières
              différentes. 2 Exemples: section avec seulement le lien avec un libellé ou
              section avec titre et lien complet. À voir selon vos préférences
            </Typography>
          </BoxResponsivePadding> */}

          {children}
        </>
      ) : (
        children
      ),
    },
  ];
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const errors = handleSimpleFormSubmit(fieldsGeneral, setFieldsGeneral);

      if (errors.length > 0) {
        setIsLoading(false);
        return;
      }
      await onSubmit();

      setIsLoading(false);
    } catch (error) {
      showSnackbar("Une erreur inconnue s'est produite. Veuillez réessayer." , "error")
    }
  };
  return (
    <PageTitleLayout
      title="Modifier demande"
      globalLoading={isLoading}
      sideTitleComponent={
        <CustomButton secondary onClick={handleSubmit} disabled={isLoading}>
          Enregistrer
        </CustomButton>
      }
      underTitleComponent={
        parentIncidentId ? (
          <Typography
            color="white"
            fontWeight="bold"
            component={Link}
            to={`${routes.demands.path}/${parentIncidentId}/?tab=0`}
          >
            Demande parent
          </Typography>
        ) : undefined
      }
    >
      <BasicTabs tabs={tabData} />
    </PageTitleLayout>
  );
};

export default EditDemandLayout;
