import React, { createContext, useCallback, useEffect, useState } from "react";
import ConfirmationDialog, {
  DialogConfig,
} from "../components/dialog/ConfirmationDialog/ConfirmationDialog";

type Props = {
  children: React.ReactNode;
};

type ConfirmationContextType = (config?: DialogConfig) => Promise<boolean>;

export const ConfirmationContext = createContext<
  ConfirmationContextType | undefined
>(undefined);

const defaultConfig: DialogConfig = {
  title: "Confirmation",
  message: "Voulez-vous continuer",
  confirmText: "Confirmer",
};

const ConfirmationProvider = ({ children }: Props) => {
  const [resolve, setResolve] = useState<((value: boolean) => void) | undefined>(undefined);
  const [config, setConfig] = useState<DialogConfig>(defaultConfig);

  
  const confirm = useCallback( async (config?: DialogConfig) =>
    new Promise<boolean>((res) => {
      setConfig(config ? config : defaultConfig);
      setResolve(() => res);
    }),
    []
  );

  const handleConfirm = () => {
    resolve?.(true);
    setResolve(undefined);
  };

  const handleCancel = () => {
    resolve?.(false);
    setResolve(undefined);
  };

  return (
    <ConfirmationContext.Provider value={confirm}>
      <ConfirmationDialog
        open={resolve !== undefined}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
        config={config}
      />
      {children}
    </ConfirmationContext.Provider>
  );
};

export default ConfirmationProvider;
