import { Box, Dialog, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SimpleFormWithSectionsWithState from "../../../../form/SimpleForm/SimpleFormWithSectionsWithState";
import Transition from "../../../../common/animation/DialogTransition";
import { Route, useNavigate } from "react-router-dom";
import useUser from "../../../../../hooks/useUserData";
import { ContactRow } from "../../../../../data/Contact/models";
import {
  DropDownChoiceWithLabel,
  ErrorType,
  QueryError,
} from "../../../../../types";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import { RouterRoutes, routes } from "../../../../../routes/routes";
import { ServiceRow } from "../../../../../data/Service/models";
import clone from "just-clone";
import { createIncident } from "../../../../../data/Incident/queries";
import { newDemandFields } from "../../../../../data/Incident/forms/dialog";
import { buildPartialFormObj } from "../../../../form/SimpleForm/buildForm";
import { useMutation } from "@tanstack/react-query";
import { IncidentPost } from "../../../../../data/Incident/models";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  contacts: ContactRow[];
  selectedType: ServiceRow;
};

const NewDemandDialog = ({ open, setOpen, contacts, selectedType }: Props) => {
  const { userData, userHasRole } = useUser();

  const [fields, setFields] = useState(
    buildPartialFormObj(newDemandFields, {
      vdl_contactprincipal: {
        value: userData.contactid,
        choices: (userHasRole("Administrateur")
          ? contacts.filter((e) => e.statecode === 0)
          : contacts.filter(
              (e) => e.statecode === 0 && e.contactid === userData.contactid
            )
        ).map((contact) => {
          return {
            label: `${contact.firstname} ${contact.lastname}${
              contact.contactid === userData.contactid ? " (vous)" : ""
            }`,
            value: contact.contactid,
          };
        }),
      },
    })
  );

  const mutation = useMutation<string | undefined, QueryError, IncidentPost>({
    mutationFn: (incident) => createIncident(incident),
    onSuccess: (data?: string) => {
      if (data) {
        showSnackbar("Demande créée", "success");
        navigate(`${RouterRoutes.Demands}/${data}/${RouterRoutes.Edit}?tab=1`);
        setOpen(false);
      }
    },
    onError: (error: QueryError) => {
      showErrorSnackbar(
        "Erreur dans la création de la demande.",
        "error",
        error
      );
      setOpen(false);
    },
  });
  const navigate = useNavigate();
  const { showSnackbar, showErrorSnackbar } = useSnackbar();

  async function handleSubmit() {
    if (!fields.vdl_contactprincipal.value || !selectedType.vdl_typedeserviceid)
      return;
    mutation.mutate({
      title: fields.title.value,
      _vdl_contactprincipal_value: fields.vdl_contactprincipal.value,
      _vdl_typededemande_value: selectedType.vdl_typedeserviceid,
      _parentcaseid_value: null,
    });
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth="md"
      fullWidth
    >
      <Box padding="16px 16px 0 16px">
        <Typography variant="h5" color="primary" sx={{ paddingBottom: "20px" }}>
          {selectedType?.vdl_name}
        </Typography>
        <Typography>
          Après l'initiation de la demande, vous serez redirigé vers la page de
          détails de la demande pour compléter les informations spécifiques.
        </Typography>
        {selectedType.vdl_informationscomplementairesauportail && (
          <Typography marginTop="4px">
            {selectedType.vdl_informationscomplementairesauportail}
          </Typography>
        )}
      </Box>
      <SimpleFormWithSectionsWithState
        fields={fields}
        setFields={setFields}
        isFormLoading={mutation.isPending}
        columns={{ minWidth: 220, count: 1 }}
        submitText={"Initier la création"}
        handleSuccess={handleSubmit}
        cancelText="Annuler"
        handleCancel={() => setOpen(false)}
      />
    </Dialog>
  );
};

export default NewDemandDialog;
