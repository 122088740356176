import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import {
  ProgramRow,
  ClosedAccountPrograms,
} from "../../../../data/Program/models";
import { getPrograms } from "../../../../data/Program/queries";
import { QueryError } from "../../../../types";
import { isIncidentEditable } from "../../../../data/Incident/rules";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import { ContactRow } from "../../../../data/Contact/models";
import { IncidentParentRow } from "../../../../data/Incident/models";
import CustomButton from "../../../common/buttons/CustomButton";
import NewDemandDialog from "./NewDemandDialog";
import ErrorIcon from "@mui/icons-material/Error";

type Props = {
  demand: IncidentParentRow;
  contacts: ContactRow[];
};

const NewDemandButton = ({ demand, contacts }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const programQuery = useQuery<
    ProgramRow[] | ClosedAccountPrograms,
    QueryError
  >({
    queryKey: ["Programs"],
    queryFn: () => getPrograms(),
    staleTime: 600000,
    gcTime: 600000,
  });

  if (!isIncidentEditable(demand)) return null;

  if (programQuery.isLoading)
    return (
      <Box display="flex" justifyContent="end">
        <CustomButton
          primary
          onClick={() => setDialogOpen(true)}
          disabled
          endIcon={<CircularProgress size="1rem" />}
        >
          Ajouter
        </CustomButton>
      </Box>
    );

  if (programQuery.error || !programQuery.data) {
    return (
      <Box display="flex" justifyContent="end">
        <Tooltip title={"Erreur dans la récupération des programmes"}>
          <span>
            <CustomButton
              primary
              onClick={() => setDialogOpen(true)}
              disabled
              endIcon={<ErrorIcon color="error" fontSize="small" />}
              sx={{ maxHeight: "2.25rem" }}
            >
              Ajouter
            </CustomButton>
          </span>
        </Tooltip>
      </Box>
    );
  }

  if ("reason" in programQuery.data) {
    return null;
  }

  return (
    <>
      <NewDemandDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        contacts={contacts}
        demand={demand}
        programs={programQuery.data}
      />
      <Box display="flex" justifyContent="end">
        <CustomButton primary onClick={() => setDialogOpen(true)}>
          Ajouter
        </CustomButton>
      </Box>
    </>
  );
};

export default NewDemandButton;
